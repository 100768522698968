import React from 'react';
import PropTypes from 'prop-types';
import '../../css/shared/Tab.css';
import ModulesOverview from '../modules/ModulesOverview';

const PatientOverview = ({ patient, assessments }) => (
  <div className="inner-tab">
    <ModulesOverview
      patient={patient}
      moduleKey="npst"
      assessments={assessments}
    />
    <ModulesOverview
      patient={patient}
      moduleKey="vmt"
      assessments={assessments}
    />
  </div>
);

PatientOverview.propTypes = {
  patient: PropTypes.object.isRequired,
  assessments: PropTypes.array.isRequired,
};

export default PatientOverview;
