import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import '../../css/shared/FormTextField.css';
import { convertErrorToReadableText } from '../../utils/format';

const FormCheckboxField = ({
  title,
  formKey,
  error,
  register,
  defaultValue,
  dataTestID,
  placeholder,
}) => (
  <div className="form-textfield-grid">
    <label htmlFor={formKey} className="form-label">
      {title}
    </label>
    <div className="form-input">
      <input
        type="checkbox"
        name={formKey}
        id={formKey}
        defaultChecked={defaultValue}
        className={error ? 'form-textfield-error' : 'form-checkbox'}
        data-testid={dataTestID}
        placeholder={placeholder}
        {...register(formKey, { required: 'This is required' })}
      />
      {error && (
        <div className="form-textfield-error">
          <ExclamationCircleIcon className="form-icon" />
        </div>
      )}
      <div className="form-error-container">
        {error && (
          <span className="form-error-text">
            {convertErrorToReadableText(error.message)}
          </span>
        )}
      </div>
    </div>
  </div>
);

FormCheckboxField.defaultProps = {
  error: undefined,
  defaultValue: undefined,
  dataTestID: undefined,
  placeholder: undefined,
};

FormCheckboxField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  error: PropTypes.object,
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  dataTestID: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormCheckboxField;
