import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Login from './Login';
import MissingResource from '../shared/MissingResource';

// A wrapper for <Route> that renders an "Unauthorized"
// page if the user isn't authorized to access a given page
function AdminAuthenticatedAppRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        if (auth.isLoading) {
          return <></>;
        }

        if (!auth.user || !auth.organization) {
          return <Login />;
        }

        if (auth.user.role === 'super_admin' || auth.user.role === 'admin') {
          return <div className="app-container">{children}</div>;
        }

        return <MissingResource />;
      }}
    />
  );
}

AdminAuthenticatedAppRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AdminAuthenticatedAppRoute;
