import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { useQueryClient } from 'react-query';

const IdleTracker = ({
  user,
  setBannerMessage,
  setDidTriggerIdleTimer,
  signout,
}) => {
  const queryClient = useQueryClient();

  const handleOnIdle = (event, idleUser) => {
    if (idleUser) {
      signout(() => {
        setBannerMessage(undefined);
        queryClient.invalidateQueries();
        setDidTriggerIdleTimer(true);
      });
    }
  };

  const idleTimeout = 30; // minutes
  useIdleTimer({
    timeout: 1000 * 60 * idleTimeout,
    onIdle: (event) => {
      handleOnIdle(event, user);
    },
    debounce: 500,
    events: ['mousedown', 'touchstart'],
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  return <></>;
};

IdleTracker.propTypes = {
  user: PropTypes.object.isRequired,
  setBannerMessage: PropTypes.func.isRequired,
  setDidTriggerIdleTimer: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired,
};

export default IdleTracker;
