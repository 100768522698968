import React, { useState } from 'react';
import { Country, State } from 'country-state-city';
import { useParams } from 'react-router-dom';
import {
  PencilIcon,
  CheckIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/solid';
import DetailItem from '../shared/DetailItem';
import DetailView from '../shared/DetailView';
import LoadingDetail from '../shared/LoadingDetail';
import useOrganization from '../../hooks/useOrganization';
import DetailDateItem from '../shared/DetailDateItem';
import Button from '../shared/Button';
import DisplayError from '../shared/DisplayError';

const getState = (organization) => {
  const state = State.getStateByCodeAndCountry(
    organization.state,
    organization.country
  );
  return state ? state.name : organization.state;
};

const getCountry = (organization) => {
  const country = Country.getCountryByCode(organization.country);
  return country ? country.name : organization.country;
};

const ShowOrganization = () => {
  const { organizationID } = useParams();
  const {
    data: organization,
    isLoading,
    isError,
    error,
  } = useOrganization(organizationID);

  const [copied, setCopied] = useState(false);

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  return (
    <DetailView
      title={organization.name}
      description="Organization Details"
      backLink="/organizations"
      additionalButtons={[
        <Button
          key="edit"
          name="Edit"
          icon={<PencilIcon />}
          link={`/organizations/${organizationID}/edit`}
          isSecondaryStyle
        />,
      ]}
    >
      <>
        <DetailItem
          title="Organization ID"
          value={organization.id}
          additionalButtons={[
            <Button
              additionalStyle="copy-to-clipboard"
              key="copy"
              isSecondaryStyle
              name={copied ? 'Copied to Clipboard!' : 'Copy to Clipboard'}
              icon={copied ? <CheckIcon /> : <ClipboardCopyIcon />}
              onClick={() => {
                navigator.clipboard.writeText(organization.id);
                setCopied(true);
              }}
            />,
          ]}
        />
        <DetailItem title="Organization Name" value={organization.name} />
        <DetailItem title="Country" value={getCountry(organization)} />
        <DetailItem title="State" value={getState(organization)} />
        <DetailItem title="City" value={organization.city} />
        <DetailItem
          title="EMR Enabled"
          value={organization.emr_enabled ? 'True' : 'False'}
        />
        <DetailDateItem title="Creation Date" value={organization.created_at} />
      </>
    </DetailView>
  );
};

export default ShowOrganization;
