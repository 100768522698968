function treatAsUTC(date) {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

function daysBetween(startDate, endDate) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

export const daysUntilPasswordExpires = (user) => {
  const presentDate = new Date();
  const expirationDate = user.password_expiration_date;
  return daysBetween(presentDate, expirationDate);
};
