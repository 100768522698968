import React from 'react';
import '../../css/shared/PageHeader.css';
import PropTypes from 'prop-types';
import ProgressSpinner from './ProgressSpinner';

const PageHeader = ({ title, subtitle, description, refreshing }) => (
  <div className="page-header">
    <p className="page-header-subtitle">{subtitle}</p>

    <div className="flex">
      <h1 className="page-header-title">{title}</h1>
      {refreshing && <ProgressSpinner styleClass="ml-5 mt-5" refreshing />}
    </div>

    <p className="page-header-description">{description}</p>
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  refreshing: PropTypes.bool,
};

PageHeader.defaultProps = {
  refreshing: false,
  subtitle: '',
};

export default PageHeader;
