import React from 'react';
import LoadingDetailImage from '../../images/loadingdetail.png';

const LoadingDetail = () => (
  <div className="mt-14 -ml-6">
    <img
      className="animate-pulse"
      src={LoadingDetailImage}
      alt="Loading Data"
    />
  </div>
);

export default LoadingDetail;
