import { useQuery } from 'react-query';
import { Users } from '../api/users';
import { userSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function useUser(organizationID, userID) {
  return useQuery([organizationID, 'user', userID], async () => {
    const { data } = await Users.get(organizationID, userID);
    validate(data, userSchema, 'user', false);
    return data;
  });
}

export default useUser;
