import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import { SortDescendingIcon, DownloadIcon } from '@heroicons/react/outline';
import Button from '../shared/Button';
import '../../css/shared/Table.css';
import '../../css/devices/DeviceTable.css';
import { capitalize, displayableDate } from '../../utils/format';
import environment from '../../config/environment';
import { useAuth } from '../../hooks/useAuth';

const RequestTable = ({ requests }) => {
  const { organization } = useAuth();
  return (
    <div className="table">
      <div className="table-inner">
        <div className="table-wrapper">
          <div className="table-wrapper-shadow">
            <table>
              <thead>
                <tr>
                  <th className="flex" scope="col">
                    Date <SortDescendingIcon className="sort-icon" />
                  </th>
                  <th scope="col">Request ID</th>
                  <th scope="col">Assessment ID</th>
                  <th scope="col">Status</th>
                  <th className="table-th-buttons" scope="col">
                    Download
                  </th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request) => (
                  <tr key={request.id}>
                    <td className="device-row-data">
                      {request.created_at && (
                        <div className="device-row-upper">
                          {displayableDate(request.created_at)}
                          <div className="device-row-lower">
                            <TimeAgo date={request.created_at} />
                          </div>
                        </div>
                      )}
                      {request.created_at === undefined && <p>Unknown</p>}
                    </td>
                    <td className="device-row-data">{request.id}</td>
                    <td className="device-row-data">{request.assessment_id}</td>
                    <td className="device-row-data">
                      {capitalize(request.description)}
                    </td>
                    <td className="patient-row-data text-center">
                      <Button
                        icon={<DownloadIcon />}
                        onClick={() => {
                          window.location.href = `${environment.apiURL}/v1/organizations/${organization.id}/requests/${request.id}/download`;
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestTable.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RequestTable;
