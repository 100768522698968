import { useMutation, useQueryClient } from 'react-query';
import { Users } from '../api/users';
import { userSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function useSwitchCurrentOrganization(userID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation(
    (newOrganizationID) =>
      Users.switchCurrentOrganization(newOrganizationID, userID),
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries();
        await validate(response.data, userSchema, 'user-org-switch', false);
        successCallback(response);
      },
    }
  );
}

export default useSwitchCurrentOrganization;
