import React from 'react';
import App from '../../config/app';
import Environment from '../../config/environment';
import '../../css/shared/Footer.css';
import { useAuth } from '../../hooks/useAuth';
import Tooltip from './Tooltip';
import packageJson from '../../../package.json';

export default function Footer() {
  const { isLoading } = useAuth();
  const formattedDeployDate = () => {
    if (!Environment.deployDate) {
      return 'Unknown Deploy Date';
    }
    return `Deployed ${new Date(Environment.deployDate).toUTCString()}`;
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <footer>
      <div className="footer-container">
        <button
          className="mx-auto focus:outline-none"
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(
              `Chronicle Web Version ${
                packageJson.version
              } (${formattedDeployDate()})`
            );
          }}
        >
          <p className="footer-text" data-tip data-for="version">
            &copy; {new Date().getFullYear()}{' '}
            <a href="https://qr8health.com">Qr8 Health</a>. All rights reserved.
          </p>
        </button>
        <Tooltip id="version">
          <p className="text-center">
            Chronicle Web Version {packageJson.version}
            <br />
            {formattedDeployDate()}
            <br />
            UDI: {App.udi}
          </p>
        </Tooltip>
      </div>
    </footer>
  );
}
