import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import { SortDescendingIcon, EyeIcon } from '@heroicons/react/outline';
import Button from '../shared/Button';
import '../../css/shared/Table.css';
import '../../css/devices/DeviceTable.css';
import {
  displayableDate,
  displayableDeviceType,
  truncate,
} from '../../utils/format';

const DeviceTable = ({ devices }) => (
  <div className="table">
    <div className="table-inner">
      <div className="table-wrapper">
        <div className="table-wrapper-shadow">
          <table>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Active</th>
                <th scope="col">Serial Number</th>
                <th className="flex" scope="col">
                  Last Used <SortDescendingIcon className="sort-icon" />
                </th>
                <th scope="col">Device</th>
                <th scope="col">App Version</th>
                <th scope="col">OS Version</th>
                <th className="table-th-buttons" scope="col">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <tr key={device.id}>
                  <td className="device-row-name">
                    {truncate(device.name, 25)}
                  </td>
                  <td className="device-row-data">
                    {device.active ? 'True' : 'False'}
                  </td>
                  <td className="device-row-data">
                    {truncate(device.serial_number, 25)}
                  </td>
                  <td className="device-row-data">
                    {device.last_used && (
                      <div className="device-row-upper">
                        {displayableDate(device.last_used)}
                        <div className="device-row-lower">
                          <TimeAgo date={device.last_used} />
                        </div>
                      </div>
                    )}
                    {device.last_used === undefined && <p>Never</p>}
                  </td>
                  <td className="device-row-data">
                    {displayableDeviceType(device.type)}
                  </td>
                  <td className="device-row-data">{device.app_version}</td>
                  <td className="device-row-data">{device.os_version}</td>
                  <td className="patient-row-data text-center">
                    <Button icon={<EyeIcon />} link={`/devices/${device.id}`} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

DeviceTable.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DeviceTable;
