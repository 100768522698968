import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CloudDownloadIcon,
} from '@heroicons/react/outline';
import PatientModuleIcons from '../modules/ModuleIcons';
import { displayableDate, displayableDateTime } from '../../utils/format';
import Tooltip from '../shared/Tooltip';
import EMRUploaded from '../../images/emr-uploaded.png';
import NoData from '../../images/emr-not-uploaded.png';
import YellowFlagImage from '../../images/yellow-flag.png';
import RedFlagImage from '../../images/red-flag.png';
import Button from '../shared/Button';
import { flagColorForAssessment } from '../../utils/scores';
import environment from '../../config/environment';
import {
  userCanDownloadAssessments,
  userCanViewUsers,
} from '../../utils/privileges';
import '../../css/assessments/AssessmentRow.css';
import { AssessmentRowExpansion } from './AssessmentRowExpansion';

const NoFlag = ({ id }) => (
  <>
    <img
      data-tip
      data-for={`noflag-${id}`}
      className="ml-4"
      alt="No Flag"
      src={NoData}
    />
    <ReactTooltip
      id={`noflag-${id}`}
      textColor="#ffffff"
      backgroundColor="#178FA1"
    >
      <span>No data has been flagged for this assessment.</span>
    </ReactTooltip>
  </>
);

const YellowFlag = ({ id }) => (
  <>
    <img
      data-tip
      data-for={`yellowflag-${id}`}
      data-testid="yellow-flag"
      className="ml-4 w-7 h-7"
      alt="Yellow Flag"
      src={YellowFlagImage}
    />
    <ReactTooltip
      id={`yellowflag-${id}`}
      textColor="#D97708"
      backgroundColor="#FEF3C7"
    >
      <span>This assessment has been flagged for moderate impairment.</span>
    </ReactTooltip>
  </>
);

const RedFlag = ({ id }) => (
  <>
    <img
      data-tip
      data-for={`redflag-${id}`}
      data-testid="red-flag"
      className="ml-4 w-7 h-7"
      alt="Red Flag"
      src={RedFlagImage}
    />
    <ReactTooltip
      id={`redflag-${id}`}
      textColor="#DC2626"
      backgroundColor="#FEF2F2"
    >
      <span>This assessment has been flagged for severe impairment.</span>
    </ReactTooltip>
  </>
);

const flagForAssessment = (assessment) => {
  const flag = flagColorForAssessment(assessment);
  switch (flag) {
    case 'yellow':
      return <YellowFlag id={assessment.id} />;
    case 'red':
      return <RedFlag id={assessment.id} />;
    default:
      return <NoFlag id={assessment.id} />;
  }
};

const AssessmentRow = ({
  user,
  patient,
  assessment,
  organization,
  expandedAssessments,
  setExpandedAssessments,
  setExpand,
  expand,
}) => (
  <>
    <tr key={assessment.id}>
      <td className="assessment-row-data">
        <PatientModuleIcons modules={assessment.modules} />
      </td>
      <td
        data-tip
        data-for={assessment.created_at}
        className="assessment-row-data"
      >
        {assessment.created_at && (
          <>
            <div className="assessment-row-upper">
              {displayableDate(assessment.created_at)}
              <div className="assessment-row-lower">
                <TimeAgo date={assessment.created_at} />
              </div>
            </div>
            <Tooltip id={assessment.created_at}>
              <span>{displayableDateTime(assessment.created_at)}</span>
            </Tooltip>
          </>
        )}

        {assessment.created_at === undefined && <p>Never</p>}
      </td>
      <td className="assessment-row-data font-medium">
        {userCanViewUsers(user) ? (
          <Link to={`/users/${assessment.user.id}`}>
            {assessment.user.first_name} {assessment.user.last_name}
          </Link>
        ) : (
          <>
            {assessment.user.first_name} {assessment.user.last_name}
          </>
        )}
      </td>
      {organization.emr_enabled && (
        <>
          <td
            data-tip
            data-for={`${assessment.id}-emr-status`}
            className="assessment-row-data"
          >
            {assessment.emr_uploaded ? (
              <img className="ml-7" alt="EMR Uploaded" src={EMRUploaded} />
            ) : (
              <img className="ml-7" alt="EMR Not Uploaded" src={NoData} />
            )}
            <Tooltip id={`${assessment.id}-emr-status`}>
              <span>
                {assessment.emr_uploaded
                  ? 'Results uploaded to EMR successfully.'
                  : 'Results have not been uploaded to the EMR.'}
              </span>
            </Tooltip>
          </td>
        </>
      )}
      <td className="assessment-row-data">{flagForAssessment(assessment)}</td>
      {userCanDownloadAssessments(user) && (
        <td className="patient-row-data text-center">
          <Button
            dataTestID="download"
            icon={<CloudDownloadIcon />}
            onClick={() => {
              window.location.href = `${environment.apiURL}/v1/organizations/${organization.id}/requests/${assessment.request_id}/download`;
            }}
          />
        </td>
      )}
      <td className="patient-row-data text-center">
        <Button
          dataTestID="expand"
          icon={
            expandedAssessments[assessment.id] ? (
              <ChevronUpIcon />
            ) : (
              <ChevronDownIcon />
            )
          }
          onClick={() => {
            const expansions = expandedAssessments;
            expansions[assessment.id] = !expansions[assessment.id];
            setExpandedAssessments(expansions);
            setExpand(!expand);
          }}
        />
      </td>
    </tr>
    {expandedAssessments[assessment.id] && (
      <AssessmentRowExpansion assessment={assessment} patient={patient} />
    )}
  </>
);

AssessmentRow.propTypes = {
  user: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  assessment: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  expandedAssessments: PropTypes.object.isRequired,
  setExpandedAssessments: PropTypes.func.isRequired,
  setExpand: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
};

NoFlag.propTypes = {
  id: PropTypes.string.isRequired,
};

YellowFlag.propTypes = {
  id: PropTypes.string.isRequired,
};

RedFlag.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AssessmentRow;
