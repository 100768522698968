import api from '../config/api';
import { generateQueryString } from '../hooks/usePagination';

export const Users = {
  create: (organizationID, params) =>
    api.post(`/organizations/${organizationID}/users`, params),

  get: (organizationID, userID) =>
    api.get(`/organizations/${organizationID}/users/${userID}`),

  list: (organizationID, page) =>
    api.get(
      `/organizations/${organizationID}/users?${generateQueryString(
        page,
        'last_name',
        'asc'
      )}`
    ),

  update: (organizationID, userID, params) =>
    api.put(`/organizations/${organizationID}/users/${userID}`, params),

  switchCurrentOrganization: (organizationID, userID) =>
    api.post(
      `/organizations/${organizationID}/users/${userID}/change-current-org`
    ),

  checkConfirmation: (confirmationToken) =>
    api.post(`/users/confirmations/${confirmationToken}`),

  confirm: (userID, password, passwordConfirmation, confirmationToken) =>
    api.post(`/users/${userID}/confirmations/confirm`, {
      password,
      password_confirmation: passwordConfirmation,
      confirmation_token: confirmationToken,
    }),

  checkResetPassword: (resetPasswordToken) =>
    api.post(`/users/passwords/${resetPasswordToken}`),

  resetPassword: (
    userID,
    newPassword,
    passwordConfirmation,
    resetPasswordToken
  ) =>
    api.post(`/users/${userID}/reset-password`, {
      password: newPassword,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken,
    }),

  changePassword: (userID, data) =>
    api.post(`/users/${userID}/update-password`, data),

  recoverUser: (email) => api.post(`/users/troubleshoot`, { email }),

  enable: (organizationID, userID) =>
    api.post(`/organizations/${organizationID}/users/${userID}/enable`),

  disable: (organizationID, userID) =>
    api.post(`/organizations/${organizationID}/users/${userID}/disable`),

  unlock: (lockedToken) => api.post(`/users/locked/${lockedToken}`),
};

export default Users;
