import React from 'react';
import Pdf from 'react-to-pdf';
import '../../css/patients/PatientPageHeader.css';
import PropTypes from 'prop-types';
import { PencilAltIcon, DownloadIcon } from '@heroicons/react/outline';
import Button from '../shared/Button';
import { useAuth } from '../../hooks/useAuth';
import { firstLetterCapitalized, displayableRaces } from '../../utils/format';
import { generatePdfFilename } from '../../utils/pdf';

const PatientPageHeader = ({
  patient,
  assessments,
  pdfRef,
  pdfVisible,
  setPdfVisible,
  showButtons,
}) => {
  const { organization } = useAuth();
  return (
    <div className="patient-page-header">
      <div className="patient-header-left">
        <p className="patient-page-header-subtitle">{organization.name}</p>
        <h1 className="patient-page-header-title">
          {patient.first_name} {patient.last_name}
        </h1>
        <div className="patient-page-header-buttons">
          <p>
            DOB: <span>{patient.date_of_birth}</span>
          </p>
          <p>
            Sex:{' '}
            {patient.sex ? (
              <span>{firstLetterCapitalized(patient.sex)}</span>
            ) : (
              <span>N/A</span>
            )}
          </p>
          <p>
            Education:{' '}
            {patient.education !== undefined ? (
              <span>{patient.education} Years</span>
            ) : (
              <span>N/A</span>
            )}
          </p>
          <p>
            MRN: <span>{patient.mrn}</span>
          </p>
          <p>
            Race: <span>{displayableRaces(patient.races)}</span>
          </p>
        </div>
        {showButtons && (
          <div className="patient-header-button-tray">
            {assessments.length > 0 && (
              <Pdf
                targetRef={pdfRef}
                filename={generatePdfFilename()}
                scale={0.8}
                x={10}
                y={10}
                onComplete={() => {
                  setPdfVisible(false);
                }}
              >
                {({ toPdf }) => (
                  <Button
                    name={pdfVisible ? 'Generating PDF...' : 'Download PDF'}
                    disabled={pdfVisible}
                    icon={<DownloadIcon />}
                    isLoading={pdfVisible}
                    onClick={() => {
                      setPdfVisible(true);

                      // State updates do not happen instantly,
                      // so we need to wait for a second.
                      //
                      // This can be improved by implementing a useEffect in ShowPatient.
                      setTimeout(() => {
                        toPdf();
                      }, 1800);
                    }}
                  />
                )}
              </Pdf>
            )}
            <Button
              name="Edit Patient"
              icon={<PencilAltIcon />}
              link={`/patients/${patient.id}/edit`}
              isSecondaryStyle
            />
          </div>
        )}
      </div>
    </div>
  );
};

PatientPageHeader.defaultProps = {
  pdfRef: undefined,
  pdfVisible: undefined,
  setPdfVisible: () => {},
  showButtons: true,
  assessments: [],
};

PatientPageHeader.propTypes = {
  patient: PropTypes.object.isRequired,
  assessments: PropTypes.arrayOf(PropTypes.object),
  pdfRef: PropTypes.object,
  pdfVisible: PropTypes.bool,
  setPdfVisible: PropTypes.func,
  showButtons: PropTypes.bool,
};

export default PatientPageHeader;
