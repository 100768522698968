import React from 'react';
import PropTypes from 'prop-types';
import '../../css/shared/FlagAlert.css';
import yellowflag from '../../images/yellowflag.png';
import redflag from '../../images/redflag.png';

const FlagAlert = ({ color }) => (
  <div className="flag-alert" data-testid={`flag-${color}`}>
    <div className={`${color}-flag-alert`} role="alert">
      <div className="flag-alert-inner">
        {color === 'red' && (
          <img className="flag-alert-icon" src={redflag} alt="Red Flag" />
        )}
        {color === 'yellow' && (
          <img className="flag-alert-icon" src={yellowflag} alt="Yellow Flag" />
        )}

        <p
          data-testid={`${color}-flag-alert-message`}
          className={`${color}-flag-alert-message`}
        >
          {color === 'red' &&
            'One or more test results are consistent with severe impairment.'}
          {color === 'yellow' &&
            'One or more test results are consistent with moderate impairment.'}
          <br />
          Additional assessment is recommended.
        </p>
      </div>
    </div>
  </div>
);

FlagAlert.propTypes = {
  color: PropTypes.string.isRequired,
};

export default FlagAlert;
