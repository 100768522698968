import { useMutation, useQueryClient } from 'react-query';
import { Patients } from '../api/patients';

function useEditPatient(organizationID, patientID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => Patients.update(organizationID, patientID, data),
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries(
          [organizationID, 'patients', 'list'],
          [organizationID, patientID, 'patients', 'show']
        );
        successCallback(response);
      },
    }
  );
}

export default useEditPatient;
