import React from 'react';

const UserAccountBackground = () => (
  <picture>
    <source srcSet="/login-medium.webp" type="image/webp" />
    <source srcSet="/login-medium.jpg" type="image/jpeg" />
    <img
      className="absolute inset-0 h-full w-full object-cover"
      src="/login-medium.jpg"
      alt=""
    />
  </picture>
);

export default UserAccountBackground;
