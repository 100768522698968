import { useMutation } from 'react-query';
import { Users } from '../api/users';

function useUnlockUser(successCallback) {
  return useMutation((lockedToken) => Users.unlock(lockedToken), {
    onSuccess: async (response) => {
      successCallback(response);
    },
  });
}

export default useUnlockUser;
