import { useQuery } from 'react-query';
import { Requests } from '../api/requests';
import { requestListSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function useRequests(organizationID, page) {
  return useQuery(
    [organizationID, 'devices', 'list', page],
    async () => {
      const { data } = await Requests.list(organizationID, page);
      await validate(data, requestListSchema, 'devices', false);
      return data;
    },
    {
      keepPreviousData: true,
    }
  );
}

export default useRequests;
