import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import NoDataPageHeader from './NoDataPageHeader';
import Button from './Button';
import { SupportEmail } from '../../utils/constants';

const MissingResource = () => (
  <NoDataPageHeader
    title="Page Not Found"
    description={
      <p className="no-data-page-header-description">
        The page you were trying to access does not exist. If you believe this
        is an error, please contact{' '}
        <a href={`mailto:${SupportEmail}`}>Support</a>.
      </p>
    }
    button={
      <Button
        name="Go Home"
        icon={<ArrowLeftIcon />}
        className="mx-auto"
        link="/"
      />
    }
  />
);

export default MissingResource;
