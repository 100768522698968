import React from 'react';
import PropTypes from 'prop-types';
import FlagAlert from '../shared/FlagAlert';
import PatientPageHeader from './PatientPageHeader';
import PatientOverview from './PatientOverview';
import cclogo from '../../images/logo.png';
import qr8Logo from '../../images/qr8logo.png';

const ResultsPDF = ({ patient, assessments }) => (
  <>
    <img className="h-10" src={cclogo} alt="Cognition Chronicle" />
    <img className="h-10 float-right -mt-11" src={qr8Logo} alt="Qr8 Health" />
    <PatientPageHeader patient={patient} showButtons={false} />
    {patient.flagged?.is_flagged && <FlagAlert color={patient.flagged.color} />}
    <PatientOverview patient={patient} assessments={assessments} />
  </>
);

ResultsPDF.propTypes = {
  patient: PropTypes.object.isRequired,
  assessments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ResultsPDF;
