import React from 'react';
import { useOrganizations } from '../../hooks/useOrganizations';
import OrganizationTable from './OrganizationTable';
import ListPage from '../shared/ListPage';
import { usePagination } from '../../hooks/usePagination';

const OrganizationList = () => {
  const { page, setPage } = usePagination();
  const query = useOrganizations(page);

  return (
    <ListPage
      title="Organizations"
      description="Your organizations are listed below."
      noObjectsTitle="No Organizations Found"
      noObjectsDescription="There was a problem loading your organizations. Please contact Support."
      newObjectButtonTitle="New Organization"
      newObjectLink="/organizations/new"
      query={query}
      page={page}
      setPage={setPage}
      table={(data) => <OrganizationTable organizations={data} />}
      hideOrganizationName
    />
  );
};

export default OrganizationList;
