import React from 'react';
import useDevices from '../../hooks/useDevices';
import DeviceTable from './DeviceTable';
import { usePagination } from '../../hooks/usePagination';
import ListPage from '../shared/ListPage';

const DeviceList = () => {
  const { page, setPage } = usePagination();
  const query = useDevices(page);

  return (
    <ListPage
      title="Device Management"
      description="The devices for this organization are listed below."
      noObjectsTitle="No Devices Found"
      noObjectsDescription="There are no devices in this organization. To add a device, click 'New Device' below."
      newObjectButtonTitle="New Device"
      newObjectLink="/devices/new"
      query={query}
      page={page}
      setPage={setPage}
      table={(data) => <DeviceTable devices={data} />}
    />
  );
};

export default DeviceList;
