import { useQuery } from 'react-query';
import { Patients } from '../api/patients';

function usePatientSearch(
  organizationID,
  searchTerm,
  searchAttribute,
  isSearching
) {
  return useQuery(
    [organizationID, searchTerm, searchAttribute, 'patients', 'search'],
    async () => {
      const { data } = await Patients.search(
        organizationID,
        searchTerm,
        searchAttribute
      );
      return data;
    },
    { enabled: isSearching }
  );
}

export default usePatientSearch;
