import React from 'react';
import { Transition } from '@headlessui/react';
import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';
import { LogoutIcon } from '@heroicons/react/outline';
import { CogIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import '../../css/navigation/Menu.css';
import '../../css/navigation/UserMenu.css';
import UserMenuItem from './UserMenuItem';
import { useAuth } from '../../hooks/useAuth';

const UserMenu = ({ visible, dismiss, offset, refp }) => {
  const queryClient = useQueryClient();
  const { signout } = useAuth();
  const history = useHistory();

  const options = [
    {
      title: 'Logout',
      description: 'End your current session',
      icon: <LogoutIcon />,
      onClick: () => {
        signout(() => {
          queryClient.invalidateQueries();
          history.push(`/`);
        });
      },
    },
  ];

  return (
    <Transition
      show={visible}
      enter="transition ease-out duration-100"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-out duration-100"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className="user-menu-container" ref={refp}>
        <div
          style={{ marginLeft: offset }}
          className="user-menu-inner-border"
          onMouseLeave={dismiss}
        >
          <div className="menu-item-container">
            <div className="menu-inner-container">
              {options.map((option) => (
                <MenuItem
                  key={option.title}
                  title={option.title}
                  description={option.description}
                  icon={option.icon}
                  link={option.link}
                  onClick={option.onClick}
                  dismiss={dismiss}
                />
              ))}
            </div>
            <div className="super-admin-menu-lower-tray">
              <div>
                <ul className="super-admin-menu-ul">
                  <UserMenuItem
                    icon={<CogIcon />}
                    name="Account Settings"
                    description="Manage your account"
                    link="/account"
                    dismiss={dismiss}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

UserMenu.defaultProps = {
  offset: 0,
};

UserMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  offset: PropTypes.number,
  refp: PropTypes.object.isRequired,
};

export default UserMenu;
