import React from 'react';
import { Transition } from '@headlessui/react';
import {
  ClipboardListIcon,
  DocumentIcon,
  ViewListIcon,
} from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import '../../css/navigation/SuperAdminMenu.css';
import SuperAdminMenuItem from './SuperAdminMenuItem';
import App from '../../config/app';

const SuperAdminMenu = ({ visible, options, dismiss, refp }) => (
  <Transition
    show={visible}
    enter="transition ease-out duration-100"
    enterFrom="opacity-0 translate-y-1 z-50"
    enterTo="opacity-100 translate-y-0"
    leave="transition ease-out duration-100"
    leaveFrom="opacity-100 translate-y-0 z-50"
    leaveTo="opacity-0 translate-y-1"
  >
    <div
      className="super-admin-menu-container"
      onMouseLeave={dismiss}
      ref={refp}
    >
      <div className="super-admin-menu-inner-border">
        <div className="super-admin-menu-item-container">
          <div className="super-admin-menu-inner-container">
            {options.map((option) => (
              <MenuItem
                key={option.title}
                title={option.title}
                description={option.description}
                icon={option.icon}
                link={option.link}
                dismiss={dismiss}
              />
            ))}
          </div>
          <div className="super-admin-menu-lower-tray">
            <div>
              <ul className="super-admin-menu-ul">
                <SuperAdminMenuItem
                  icon={<DocumentIcon />}
                  name="Instructions for Use"
                  description="View the IFU document"
                  onClick={() => {
                    window.location.href = App.ifu;
                  }}
                  dismiss={dismiss}
                />
                <SuperAdminMenuItem
                  icon={<ViewListIcon />}
                  name="Request List"
                  description="View all API requests"
                  link="/requests"
                  dismiss={dismiss}
                />
                <SuperAdminMenuItem
                  icon={<ClipboardListIcon />}
                  name="Audit Logs"
                  description="View logs related to user activity"
                  link="/audit-logs"
                  dismiss={dismiss}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
);

SuperAdminMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  dismiss: PropTypes.func.isRequired,
  refp: PropTypes.object.isRequired,
};

export default SuperAdminMenu;
