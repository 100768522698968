/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

// Given a JSON object and Yup schema, validate
// and throw/log an error
// eslint-disable-next-line import/prefer-default-export
export default async function validate(data, schema, type, secure = true) {
  await schema.validate(data, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      // Log the errors to the console for easier debugging
      const message = `Invalid object '${type}': ${JSON.stringify(
        data
      )}. Validation error: ${e.message}`;
      console.log(e.message);

      // Only log to Sentry for validations marked
      // explicitly for secure=false
      if (secure === false) {
        Sentry.captureMessage(message);
      }
    });
    console.log(`Invalid JSON for '${type}': `, JSON.stringify(data));
    throw new Error(err);
  });
}
