import React from 'react';
import useRequests from '../../hooks/useRequests';
import RequestTable from './RequestTable';
import { usePagination } from '../../hooks/usePagination';
import ListPage from '../shared/ListPage';
import { useAuth } from '../../hooks/useAuth';

const RequestList = () => {
  const { organization } = useAuth();
  const { page, setPage } = usePagination();
  const query = useRequests(organization.id, page);

  return (
    <ListPage
      title="Request List"
      description="The API requests for this organization are listed below."
      noObjectsTitle="No Requests Found"
      noObjectsDescription="There have been no API requests for this organization."
      query={query}
      page={page}
      setPage={setPage}
      table={(data) => <RequestTable requests={data} />}
    />
  );
};

export default RequestList;
