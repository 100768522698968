import React from 'react';
import PropTypes from 'prop-types';
import '../../css/assessments/LatestScore.css';
import {
  calculateLatestScoreText,
  totalValidModulesInAssessments,
  moduleIsValid,
  latestValidModuleOfType,
  round,
} from '../../utils/scores';
import { NoScoresWarning } from '../shared/NoScoresWarning';

const LatestScore = ({ patient, assessments, moduleKey }) => {
  if (assessments.length === 0) {
    return (
      <p className="no-scores">No tests have been uploaded for this patient.</p>
    );
  }
  if (totalValidModulesInAssessments(assessments, moduleKey) === 0) {
    return (
      <div className="no-scores">
        <NoScoresWarning patient={patient} />
      </div>
    );
  }

  const module = latestValidModuleOfType(assessments, moduleKey);
  if (module === undefined) {
    return (
      <p className="no-scores">No tests have been uploaded for this patient.</p>
    );
  }

  if (!moduleIsValid(module)) {
    return (
      <p className="no-scores">
        The latest test for this patient does not contain a score.
      </p>
    );
  }

  return (
    <div className="latest-score">
      <div className="latest-score-description">
        <h1>Latest Score</h1>
        <div>{calculateLatestScoreText(patient, assessments, moduleKey)}</div>
      </div>
      <div className="score-values">
        <div className="score raw-score">
          <h1>Raw Score</h1>
          <h2>{module.raw_score && module.raw_score}</h2>
        </div>
        <div className="score z-score">
          <h1>Adjusted Z-Score</h1>
          {module.zscore && (
            <h2>
              {module.zscore > 0 ? '+' : ''}
              {round(module.zscore)}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

LatestScore.propTypes = {
  assessments: PropTypes.array.isRequired,
  moduleKey: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
};

export default LatestScore;
