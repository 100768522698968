import React from 'react';
import PropTypes from 'prop-types';
import { SortDescendingIcon } from '@heroicons/react/solid';
import TimeAgo from 'react-timeago';
import { EyeIcon } from '@heroicons/react/outline';
import Button from '../shared/Button';
import '../../css/shared/Table.css';
import '../../css/users/UserTable.css';
import {
  displayableUserStatus,
  displayableDate,
  truncate,
  displayableRole,
} from '../../utils/format';

const UserTable = ({ users }) => (
  <div className="table">
    <div className="table-inner">
      <div className="table-wrapper">
        <div className="table-wrapper-shadow">
          <table>
            <thead>
              <tr>
                <th className="flex" scope="col">
                  Name <SortDescendingIcon className="sort-icon" />
                </th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Last Login</th>
                <th scope="col">Status</th>
                <th className="table-th-buttons" scope="col">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="user-row-name">
                    {truncate(`${user.last_name}, ${user.first_name}`, 25)}
                  </td>
                  <td className="user-row-data">
                    <a href={`mailto:${user.email}`}>
                      {truncate(user.email, 25)}
                    </a>
                  </td>
                  <td className="user-row-data">
                    {displayableRole(user.role)}
                  </td>
                  <td className="user-row-data">
                    {user.last_login_date && (
                      <div className="user-row-upper">
                        {displayableDate(user.last_login_date)}
                        <div className="user-row-lower">
                          <TimeAgo date={user.last_login_date} />
                        </div>
                      </div>
                    )}
                    {user.last_login_date === undefined && <p>Never</p>}
                  </td>
                  <td className="user-row-data">
                    {displayableUserStatus(user)}
                  </td>
                  <td className="patient-row-data text-center">
                    <Button icon={<EyeIcon />} link={`/users/${user.id}`} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

UserTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserTable;
