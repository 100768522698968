import { useState, useEffect } from 'react';
import { Country, State, City } from 'country-state-city';

function useCountryStateCity(
  selectedCountry,
  selectedState,
  selectedCity,
  setValue,
  getValues
) {
  const countries = Country.getAllCountries().map((c) => ({
    value: c.isoCode,
    title: c.name,
  }));
  const [states, setStates] = useState([{ value: '', title: '' }]);
  const [cities, setCities] = useState([{ value: '', title: '' }]);
  const emptyValue = { value: 'N/A', title: 'N/A' };

  useEffect(() => {
    // Get the current country and state codes
    const countryCode = getValues('country');
    let stateCode = getValues('state');
    const city = getValues('city');

    // Update the states based on the current country
    let newStates = State.getStatesOfCountry(countryCode).map((c) => ({
      value: c.isoCode,
      title: c.name,
    }));

    // If we couldn't find any states, add a 'N/A' value
    if (newStates.length === 0) {
      newStates = [emptyValue];
      setStates(newStates);
    } else {
      setStates(newStates);
    }

    // If the country we switched to does not have the same state code,
    // reset it to the first state code, if available
    const matchingStates = newStates.filter((s) => s.value === stateCode);
    if (matchingStates.length === 0) {
      stateCode = newStates[0].value;
      setValue('state', newStates[0].value);
    }

    // Update the states based on either the country or country and state
    let newCities = [];
    if (stateCode) {
      newCities = City.getCitiesOfState(countryCode, stateCode).map((c) => ({
        value: c.name,
        title: c.name,
      }));
    } else {
      newCities = City.getCitiesOfCountry(countryCode).map((c) => ({
        value: c.name,
        title: c.name,
      }));
    }

    // If we couldn't find any cities, add a 'N/A' value
    if (newCities.length > 0) {
      setCities(newCities);
    } else {
      newCities = [emptyValue];
      setCities(newCities);
    }

    const matchingCities = newCities.filter((c) => c.value === city);
    if (matchingCities.length === 0) {
      setValue('city', newCities[0].value);
    }
  }, [selectedCountry, selectedState, selectedCity]);

  return { countries, states, cities };
}

export default useCountryStateCity;
