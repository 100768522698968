import { useMutation, useQueryClient } from 'react-query';
import { Organizations } from '../api/organizations';

function useEditOrganization(organizationID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation((data) => Organizations.update(organizationID, data), {
    onSuccess: async (response) => {
      queryClient.invalidateQueries(
        ['organizations', 'list'],
        [organizationID, 'organizations', 'show']
      );
      successCallback(response);
    },
  });
}

export default useEditOrganization;
