export const ageLowerBound = 18;
export const ageLowerBoundSign = '<';

export const ageUpperBound = 90;
export const ageUpperBoundSign = '>=';

export const isBelowLowerAgeBound = (age) => age < 18;
export const isAboveUpperAgeBound = (age) => age >= 90;
export const isWithinAgeRange = (age) =>
  !isBelowLowerAgeBound(age) && !isAboveUpperAgeBound(age);
