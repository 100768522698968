import {
  CollectionIcon,
  DeviceMobileIcon,
  DocumentIcon,
  OfficeBuildingIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/solid';
import App from '../config/app';

const superAdminMenuOptions = [
  {
    title: 'Patients',
    description: 'View patient data',
    icon: <UsersIcon />,
    link: '/patients',
  },
  {
    title: 'Users',
    description: 'View and manage users',
    icon: <UserCircleIcon />,
    link: '/users',
  },
  {
    title: 'Devices',
    description: 'View and manage devices',
    icon: <DeviceMobileIcon />,
    link: '/devices',
  },
  {
    title: 'Organizations',
    description: 'View and manage organizations',
    icon: <OfficeBuildingIcon />,
    link: '/organizations',
  },
  {
    title: 'Research CSV',
    description: 'Generate CSV files',
    icon: <CollectionIcon />,
    link: '/research-csv',
  },
];

const adminMenuOptions = [
  {
    title: 'Patients',
    description: 'View patient data',
    icon: <UsersIcon />,
    link: '/patients',
  },
  {
    title: 'Users',
    description: 'View and manage users',
    icon: <UserCircleIcon />,
    link: '/users',
  },
  {
    title: 'Devices',
    description: 'View and manage devices',
    icon: <DeviceMobileIcon />,
    link: '/devices',
  },
  {
    title: 'Instructions for Use',
    description: 'View the IFU document',
    icon: <DocumentIcon />,
    onClick: () => {
      window.location.href = App.ifu;
    },
  },
  {
    title: 'Research CSV',
    description: 'Generate CSV files',
    icon: <CollectionIcon />,
    link: '/research-csv',
  },
];

const userMenuOptions = [
  {
    title: 'Patients',
    description: 'View patient data',
    icon: <UsersIcon />,
    link: '/patients',
  },
  {
    title: 'Instructions for Use',
    description: 'View the IFU document',
    icon: <DocumentIcon />,
    onClick: () => {
      window.location.href = App.ifu;
    },
  },
];

function generateMenuOptions(role) {
  switch (role) {
    case 'super_admin':
      return superAdminMenuOptions;
    case 'admin':
      return adminMenuOptions;
    default:
      return userMenuOptions;
  }
}

export default generateMenuOptions;
