export function titleCase(string) {
  if (string === undefined) {
    return '';
  }
  const sentence = string.replace('_', ' ').toLowerCase().split(' ');
  for (let i = 0; i < sentence.length; i += 1) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(' ');
}

export function displayableUserStatus(user) {
  if (user.active === false) {
    return 'Disabled';
  }
  if (user.locked) {
    return 'Locked';
  }
  if (user.email_confirmed === false) {
    return 'Unconfirmed';
  }
  return 'Active';
}

export function displayableRole(role) {
  if (role === 'user') {
    return 'HCP';
  }
  if (role === 'admin') {
    return 'Admin';
  }
  if (role === 'super_admin') {
    return 'Super Admin';
  }
  return 'Unknown';
}

export function displayableDateFromDate(date) {
  if (date === undefined) {
    return date;
  }
  const offset = date.getTimezoneOffset();
  const dateOffsetted = new Date(date.getTime() - offset * 60 * 1000);
  return dateOffsetted.toISOString().split('T')[0];
}

export function displayableDate(dateString) {
  const date = new Date(dateString);
  return displayableDateFromDate(date);
}

export const displayableShortDate = (date) =>
  date.toLocaleString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  });

export function displayableDeviceType(deviceType) {
  const lookup = { ipad: 'iPad' };
  return lookup[deviceType] || deviceType;
}

export function displayableError(error, resourceType) {
  if (error.message.includes('409')) {
    return `The provided ${
      resourceType === 'user' ? 'email' : 'organization name'
    } already exists`;
  }
  return error.message;
}

function removeLastInstance(strToRemove, input) {
  const charpos = input.lastIndexOf(strToRemove);
  if (charpos < 0) return input;
  return (
    input.substring(0, charpos) + input.substring(charpos + strToRemove.length)
  );
}

export function displayableDateTime(dateString) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };

  // Return the formatted date without the extra commma.
  // There doesn't seem to be a way to do this with .toLocaleString itself
  return removeLastInstance(
    ',',
    new Date(dateString).toLocaleString(undefined, options)
  );
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function firstLetterCapitalized(s) {
  if (s === undefined) {
    return 'UNDEFINED';
  }
  return s.charAt(0).toUpperCase();
}

export function raceForKey(key) {
  const races = {
    'american-indian-alaska-native': 'American Indian or Alaska Native',
    asian: 'Asian',
    'black-african-american': 'Black or African American',
    'native-hawaiian-other-pacific-islander':
      'Native Hawaiian or Other Pacific Islander',
    white: 'White',
    other: 'Other',
    unknown: 'Unknown',
    'choose-not-to-report': 'Undisclosed',
  };
  return races[key];
}

export function displayableRaces(races) {
  if (races === undefined) {
    return 'N/A';
  }
  return races.map((r) => raceForKey(r) || r).join(', ');
}

// Convert errors like "device_name is required" to "Device name is required"
export function convertErrorToReadableText(errorText) {
  const text = errorText.split(' ')[0];
  const result = text.replace(/([A-Z])/g, ' $1').replace('_', ' ');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return errorText.replace(text, finalResult);
}

export const getYear = (date) => date.getFullYear();
export const getMonth = (date) => date.getMonth();

export function range(start, end) {
  const ans = [];
  for (let i = start; i <= end; i += 1) {
    ans.push(i);
  }
  return ans;
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const years = range(1940, getYear(new Date()));

// Strip formatting from phone number so links work
export const stripPhoneNumberFormatting = (phoneNumber) => {
  if (!phoneNumber || phoneNumber === '') {
    return '';
  }
  return phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '');
};

export const formatPhoneNumber = (str) => {
  if (!str || str === '') {
    return '';
  }
  const cleaned = str.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const truncate = (input, maxChars) =>
  input.length > maxChars ? `${input.substring(0, maxChars)}...` : input;
