import { useState, useEffect } from 'react';

const queryString = require('query-string');

const perPage = 25;
export function generateQueryString(page, sort, order) {
  return `offset=${
    page * perPage
  }&limit=${perPage}&sort=${sort}&order=${order}`;
}

export function generateSortQueryString(sort, order) {
  return `sort=${sort}&order=${order}`;
}

export const usePagination = (response) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const query = queryString.parse(location.search);
    if (query.page && query.page > 0) {
      setPage(query.page - 1);
    }
  }, [response]);

  return { page, setPage };
};
