import React from 'react';
import PropTypes from 'prop-types';
import '../../css/shared/ErrorAlert.css';

const FormError = ({ message }) => (
  <div className="pt-5">
    <div className="error-alert" role="alert">
      <p className="error-alert-message">{message}</p>
    </div>
  </div>
);

FormError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FormError;
