const roles = { super_admin: 3, admin: 2, user: 1 };
export function userCanEditUser(userWhoIsEditing, userBeingEdited) {
  return roles[userWhoIsEditing.role] >= roles[userBeingEdited.role];
}

export function userCanDeactivateUser(userWhoIsEditing, userBeingEdited) {
  if (userWhoIsEditing.id === userBeingEdited.id) {
    return false;
  }
  return roles[userWhoIsEditing.role] >= roles[userBeingEdited.role];
}

export function userCanActivateUser(userWhoIsEditing, userBeingEdited) {
  return roles[userWhoIsEditing.role] >= roles[userBeingEdited.role];
}

export function userCanDownloadAssessments(user) {
  return roles[user.role] === roles.super_admin;
}

export function userCanViewOrganization(user) {
  return roles[user.role] === roles.super_admin;
}

export function userCanViewUsers(user) {
  return roles[user.role] >= roles.admin;
}
