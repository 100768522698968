import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import { RefreshIcon } from '@heroicons/react/solid';
import { QRCode } from 'react-qrcode-logo';
import { useConditionalTimeout } from 'beautiful-react-hooks';
import qr8logo from '../../images/qr8logosquared.png';
import '../../css/shared/DetailItem.css';
import '../../css/shared/QrCodeItem.css';
import Button from './Button';

const dateInFuture = (date) => date > new Date();

const QrCodeItem = ({ device, regenerate, isLoading }) => {
  // Calculate how many seconds remain before the time expires
  const secondsRemaining = () => {
    if (dateInFuture(new Date(device.qr_code_expiration))) {
      return Math.abs(
        (new Date().getTime() - new Date(device.qr_code_expiration).getTime()) /
          1000
      );
    }
    return 0;
  };

  // Hold the remaining time as state
  const [remainingTime, setRemainingTime] = useState(secondsRemaining());
  useEffect(() => {
    setRemainingTime(secondsRemaining());
  }, [device.qr_code_expiration]);

  // When the timeout expires, update the state,
  // which we use to redraw the page
  useConditionalTimeout(
    () => {
      setRemainingTime(0);
    },
    remainingTime * 1000,
    dateInFuture(new Date(device.qr_code_expiration)) && remainingTime > 0
  );

  return (
    <div className="detail-item-textfield-grid">
      <label htmlFor="QR Code" className="detail-item-label">
        QR Code
      </label>
      <div className="detail-item-input">
        <div className="detail-item-value">
          {remainingTime > 0 && (
            <>
              Expires <TimeAgo date={device.qr_code_expiration} />.
              <div className="-ml-2 mt-2">
                <QRCode
                  value={JSON.stringify(device.qr_code)}
                  logoImage={qr8logo}
                  fgColor="#005561"
                  size={300}
                />
              </div>
            </>
          )}
          {remainingTime === 0 && (
            <div className="flex space-x-5">
              <p className="expired-text">
                Expired <TimeAgo date={device.qr_code_expiration} />.
              </p>
              <Button
                key="regenerate"
                name="Regenerate"
                icon={<RefreshIcon />}
                isLoading={isLoading}
                onClick={() => {
                  regenerate();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

QrCodeItem.propTypes = {
  device: PropTypes.object.isRequired,
  regenerate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default QrCodeItem;
