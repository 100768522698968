import React, { useContext, useEffect } from 'react';
import { SaveIcon } from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../shared/Form';
import FormTextField from '../shared/FormTextField';
import PhoneNumberTextField from '../shared/PhoneNumberTextField';
import FormSelectionField from '../shared/FormSelectionField';
import DetailItem from '../shared/DetailItem';
import ErrorAlert from '../shared/ErrorAlert';
import LoadingDetail from '../shared/LoadingDetail';
import useEditUser from '../../hooks/useEditUser';
import { useAuth } from '../../hooks/useAuth';
import useUserAndOrganizations from '../../hooks/useUserWithOrganizations';
import FormMultiSelectionField from '../shared/FormMultiSelectionField';
import generateRoles from '../../utils/generateRoles';
import { userCanEditUser } from '../../utils/privileges';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import { stripPhoneNumberFormatting } from '../../utils/format';
import DisplayError from '../shared/DisplayError';

const userSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  role: yup.string().required(),
  phone: yup.string(),
  organization_ids: yup.array().min(1),
});

const EditUser = () => {
  const { organization, user: currentUser } = useAuth();
  const { userID } = useParams();
  const history = useHistory();
  const setBannerMessage = useContext(InformationBannerContext);

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  // API handler
  const mutation = useEditUser(organization.id, userID, (response) => {
    setBannerMessage('Updated user successfully.');
    history.push(`/users/${response.data.id}`);
  });

  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useUserAndOrganizations(organization.id, userID, currentUser);

  useEffect(() => {
    setValue('phone', response?.user?.phone);
  }, [response]);

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  if (!userCanEditUser(currentUser, response.user)) {
    return (
      <ErrorAlert message="You do not have permission to edit this user" />
    );
  }

  return (
    <>
      <Form
        title="Edit User"
        description="Update details about the user below."
        onSubmit={handleSubmit((data) => mutation.mutate(data))}
        submitText="Update User"
        submitIcon={<SaveIcon />}
        cancelLink={`/users/${userID}`}
        isPosting={mutation.isLoading}
        isError={mutation.isError}
        error={mutation.error}
        validationErrors={errors}
      >
        <FormTextField
          register={register}
          error={errors.first_name}
          title="First Name"
          formKey="first_name"
          defaultValue={response.user.first_name}
        />
        <FormTextField
          register={register}
          error={errors.last_name}
          title="Last Name"
          formKey="last_name"
          defaultValue={response.user.last_name}
        />

        <DetailItem title="Email" value={response.user.email} />

        <FormSelectionField
          title="Role"
          formKey="role"
          error={errors.role}
          register={register}
          defaultValue={response.user.role}
          options={generateRoles(currentUser.role)}
        />

        <PhoneNumberTextField
          error={errors.phone}
          title="Phone Number"
          formKey="phone"
          defaultValue={stripPhoneNumberFormatting(response.user.phone)}
          control={control}
          setValue={setValue}
        />

        <FormMultiSelectionField
          title="Organizations"
          formKey="organization_ids"
          error={errors.organization_ids}
          register={register}
          defaultValues={response.user.organizations.map((o) => o.id)}
          errorDescription="Please select at least one organization."
          disabledExplanation="Current Organization"
          disabledOption={organization.id}
          setValue={setValue}
          warningDialogTitle="Current Organization"
          warningDialogDescription={`Your current organization (${organization.name}) cannot be deselected. To deselect this option, please switch organizations.`}
          options={response.organizations.data
            .sort((a, b) => a.name > b.name)
            .map((o) => ({
              value: o.id,
              title: o.name,
            }))}
        />
      </Form>
    </>
  );
};

export default EditUser;
