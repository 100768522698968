import { useQuery } from 'react-query';
import { Organizations } from '../api/organizations';
import { organizationSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function useOrganization(organizationID) {
  return useQuery([organizationID, 'organizations', 'show'], async () => {
    const { data } = await Organizations.get(organizationID);
    await validate(data, organizationSchema, 'organization', false);
    return data;
  });
}

export default useOrganization;
