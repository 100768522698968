import Decimal from 'decimal.js';
import { SupportEmail } from './constants';
import { displayableDateTime, displayableShortDate } from './format';
import {
  ageLowerBound,
  ageLowerBoundSign,
  ageUpperBound,
  ageUpperBoundSign,
  isWithinAgeRange,
} from './norms';

// Round with a specific number of digits and return the rounded value
// Use the Decimal.js library to fix floating point rounding issues
export const roundAccurately = (num, decimalPlaces) =>
  new Decimal(num).toDecimalPlaces(decimalPlaces);

// We need to also use toFixed() here to preserve trailing zeroes
export const roundWithDigits = (num, decimalPlaces) =>
  roundAccurately(num, decimalPlaces).toFixed(decimalPlaces);

export const round = (value) => roundWithDigits(value, 1);

export const compareAssessments = (a, b) =>
  new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

export const modulesWithKey = (modules, moduleKey) =>
  modules.filter((m) => m.key === moduleKey);

export const moduleIsValid = (m) =>
  m &&
  m.zscore !== undefined &&
  m.raw_score !== undefined &&
  m.percentile !== undefined;

export const assessmentIsValid = (a) =>
  a !== undefined && a.education_at_assessment !== undefined;

export const validModulesWithKey = (modules, moduleKey) =>
  modules.filter((m) => m.key === moduleKey && moduleIsValid(m));

// We need to count the total number of modules for a given key
// in the assessments.
export const totalValidModulesInAssessments = (assessments, moduleKey) => {
  let total = 0;
  assessments.forEach((a) => {
    total += validModulesWithKey(a.modules, moduleKey).length;
  });
  return total;
};

export const latestValidModuleOfType = (assessments, moduleKey) => {
  // Flatten the sorted array of assessments into a single array of modules
  const modules = assessments
    .filter(assessmentIsValid)
    .sort(compareAssessments)
    .reduce(
      (acc, a) => acc.concat(validModulesWithKey(a.modules, moduleKey)),
      []
    );
  return modules.pop();
};

export const latestAssessmentContainingValidModuleOfType = (
  assessments,
  moduleKey
) => {
  const filteredAssessments = assessments
    .filter(assessmentIsValid)
    .sort(compareAssessments)
    .filter((a) => {
      const modules = validModulesWithKey(a.modules, moduleKey);
      return modules.length > 0 ? a : null;
    });
  return filteredAssessments.pop();
};

export const calculateAge = (dateOfBirth, todaysDate) => {
  const ageDifMs = todaysDate - dateOfBirth.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const calculateSpecificScoreText = (patient, assessment, module) => {
  const date = assessment.created_at
    ? new Date(assessment.created_at)
    : undefined;
  if (moduleIsValid(module) && assessmentIsValid(assessment)) {
    const percentile = roundWithDigits(module.percentile * 100.0, 1);
    const age = calculateAge(new Date(patient.date_of_birth), Date.now());
    const sex = patient.sex === 'male' ? 'males' : 'females';
    const education = assessment.education_at_assessment;
    const educationSuffix = education > 1 || education === 0 ? 's' : '';

    return (
      <p>
        The assessment on <b>{displayableShortDate(date).toString()}</b>, was
        better than <b>{percentile}%</b> of <b>{age.toString()}</b> year-old{' '}
        <b>{sex}</b> with <b>{education}</b> year{educationSuffix} of education.
      </p>
    );
  }

  return (
    <>
      <p>
        The most recent assessment on{' '}
        <b>{displayableShortDate(date).toString()}</b> was not scored.
      </p>
      <br />
      <p>
        If you believe this is incorrect, please contact{' '}
        <a href={`mailto:${SupportEmail}`}>Support</a>.
      </p>
    </>
  );
};

export const calculateLatestScoreText = (patient, assessments, moduleKey) => {
  const module = latestValidModuleOfType(assessments, moduleKey);
  if (module === undefined) {
    return 'The patient has not completed this module.';
  }
  const assessment = latestAssessmentContainingValidModuleOfType(
    assessments,
    moduleKey
  );
  return calculateSpecificScoreText(patient, assessment, module);
};

export const validModulesWithScores = (modules) =>
  modules.filter(
    (m) => moduleIsValid(m) && m.key !== 'mh' && m.canceled !== true
  );

export const scoreIsModeratelyImpaired = (score) => {
  const rounded = round(score);
  return rounded <= -1.5 && rounded > -2.0;
};

export const scoreIsSeverelyImpaired = (score) => round(score) <= -2.0;

export const scoreDescriptionForModule = (module) => {
  if (module === undefined) {
    return '[test not taken]';
  }
  if (scoreIsModeratelyImpaired(module.zscore)) {
    return 'moderate impairment';
  }
  if (scoreIsSeverelyImpaired(module.zscore)) {
    return 'severe impairment';
  }
  return 'normal';
};

export const generateResultText = (patient, assessments) => {
  // Handle no assessments
  if (assessments === undefined || assessments.length === 0) {
    return 'No assessments have been completed for this patient.';
  }

  const sortedAssessments = assessments.sort(compareAssessments);
  const latestAssessment = sortedAssessments[sortedAssessments.length - 1];

  const assessmentDate = displayableDateTime(latestAssessment.created_at);
  const patientDetails = `Patient Name: ${patient.last_name}, ${patient.first_name}\nMRN: ${patient.mrn}\nAssessment Date: ${assessmentDate}`;

  const moduleKeys = [
    { key: 'npst', title: 'PST' },
    { key: 'vmt', title: 'VMT' },
  ];
  const scores = [];
  const ranges = [];

  moduleKeys.forEach((o) => {
    const filteredModules = modulesWithKey(latestAssessment.modules, o.key);
    const valid = moduleIsValid(filteredModules[0]);
    if (!valid) {
      return;
    }

    const module = valid ? filteredModules[0] : undefined;
    const score =
      module &&
      `${o.title} (percentile, z-score, raw): ${round(
        module.percentile * 100.0
      )}, ${round(module.zscore)}, ${round(module.raw_score)}`;
    scores.push(score);

    const range = `Patient scored in the ${scoreDescriptionForModule(
      module
    )} range for the ${o.title}.`;
    ranges.push(range);
  });

  if (scores.length === 0) {
    const age = calculateAge(new Date(patient.date_of_birth), Date.now());
    if (isWithinAgeRange(age)) {
      // The patient is within the age range but hasn't completed any assessments
      return `The patient needs to complete the VMT or PST module to have a score.`;
    }
    return `The patient is outside the age range and will not have scored tests. Age must be ${ageLowerBoundSign} ${ageLowerBound} and ${ageUpperBoundSign} ${ageUpperBound}.`;
  }

  const moduleScores = scores.join('\n');
  const moduleRangeScores = ranges.join('\n');

  return `${patientDetails}\n\nFurther screening confirmed the need to test for cognitive impairment in order to ensure proper treatment. Results are as follows (relative to normed data):\n${moduleScores}\n\n${moduleRangeScores}\n\nA computerized assessment tool containing the Processing Speed Test (PST) and Verbal Memory Test (VMT) was administered by medical staff in the office and scored using a demographically adjusted normative model, along with a verbal orientation, taking a total time of approximately 20 minutes. These tests assess working memory, processing speed, spatial memory and verbal memory.`;
};

export const flagColorForAssessment = (assessment) => {
  let flag;

  for (let i = 0; i < assessment.modules.length; i += 1) {
    const m = assessment.modules[i];

    if (m.flagged && m.flagged.is_flagged && m.flagged.color === 'red') {
      // The first red flag overrides all other flags, so we're finished
      flag = 'red';
      return flag;
    }

    if (m.flagged && m.flagged.is_flagged && m.flagged.color === 'yellow') {
      // The current flag is yellow, but it might get overridden by a red flag
      flag = 'yellow';
    }
  }

  return flag;
};
