/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../css/shared/Button.css';
import { CloudDownloadIcon } from '@heroicons/react/outline';

const Button = ({
  name,
  icon,
  link,
  isSecondaryStyle,
  onClick,
  isLoading,
  disabled,
  additionalStyle,
  type,
  dataTestID,
}) => {
  // So users of Button don't need to know how to style `icon`,
  // we clone the component and dynamically add the className here.
  const styledIcon = React.cloneElement(icon, {
    className: `qr8-button-icon ${name ? '' : 'qr8-small-button'}`,
  });

  // If the button is denoting a 'Loading' state, we should
  // use a different icon than the one supplied and animate it
  const generateIcon = () => {
    if (isLoading) {
      return (
        <svg
          className={`animate-spin h-5 w-$ ${
            name !== undefined ? 'mr-3 -ml-1' : 'mr-0'
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      );
    }
    return styledIcon;
  };

  // Some buttons link to somewhere. Others use onClick.
  if (link) {
    return (
      <Link to={link}>
        <button
          data-testid={dataTestID}
          type={type}
          disabled={disabled}
          className={`${
            isSecondaryStyle ? 'qr8-button-secondary' : 'qr8-button'
          } ${additionalStyle || ''}`}
        >
          {generateIcon()}
          {name}
        </button>
      </Link>
    );
  }

  return (
    <button
      data-testid={dataTestID}
      type={type}
      onClick={onClick}
      className={`${isSecondaryStyle ? 'qr8-button-secondary' : 'qr8-button'} ${
        additionalStyle || ''
      }`}
      disabled={disabled}
    >
      {generateIcon()}
      {name}
    </button>
  );
};

Button.defaultProps = {
  name: undefined,
  isSecondaryStyle: false,
  icon: <CloudDownloadIcon />,
  onClick: () => {},
  link: '',
  isLoading: false,
  disabled: false,
  additionalStyle: '',
  type: 'button',
  dataTestID: '',
};

Button.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.element,
  link: PropTypes.string,
  isSecondaryStyle: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalStyle: PropTypes.string,
  type: PropTypes.string,
  dataTestID: PropTypes.string,
};

export default Button;
