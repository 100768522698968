/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import '../../css/shared/FormTextField.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  convertErrorToReadableText,
  getMonth,
  getYear,
  months,
  years,
} from '../../utils/format';

const DateTextField = ({ title, formKey, error, control }) => (
  <div className="form-textfield-grid">
    <label htmlFor={formKey} className="form-label">
      {title}
    </label>
    <div className="form-input">
      <Controller
        control={control}
        name={formKey}
        render={({ field: { onChange, value, ref } }) => (
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="date-picker">
                <button
                  className="date-picker-button"
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                <select
                  className="date-picker-selection"
                  value={months[getMonth(date)]}
                  onChange={({ target: { value: changedValue } }) =>
                    changeMonth(months.indexOf(changedValue))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  className="date-picker-selection"
                  value={getYear(date)}
                  onChange={({ target: { value: changedValue } }) => {
                    changeYear(changedValue);
                  }}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  className="date-picker-button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
            )}
            selected={value}
            onChange={onChange}
            className={error ? 'form-textfield-error' : 'form-field'}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
            inputRef={ref}
          />
        )}
      />

      {error && (
        <div className="form-textfield-error">
          <ExclamationCircleIcon className="form-icon" />
        </div>
      )}

      <div className="form-error-container">
        {error && (
          <span className="form-error-text">
            {convertErrorToReadableText(error.message)}
          </span>
        )}
      </div>
    </div>
  </div>
);

DateTextField.defaultProps = {
  error: undefined,
};

DateTextField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
};

export default DateTextField;
