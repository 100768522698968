import React, { useState } from 'react';
import {
  ClipboardCheckIcon,
  ChartSquareBarIcon,
  CloudDownloadIcon,
} from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useParams, useHistory } from 'react-router';
import { useAuth } from '../../hooks/useAuth';
import PatientPageHeader from './PatientPageHeader';
import LoadingPatientDetail from '../shared/LoadingPatientDetail';
import FlagAlert from '../shared/FlagAlert';
import usePatientAndAssessments from '../../hooks/usePatientAndAssessments';
import TabView from '../shared/TabView';
import PatientOverview from './PatientOverview';
import Button from '../shared/Button';
import AssessmentList from '../assessments/AssessmentList';
import PatientDownloads from './PatientDownloads';
import ResultsPDF from './ResultsPDF';
import DisplayError from '../shared/DisplayError';

const ShowPatient = () => {
  const { organization } = useAuth();
  const { patientID } = useParams();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('overview');

  const ref = React.createRef();
  const [pdfVisible, setPdfVisible] = useState(false);

  const { data, isLoading, isError, error } = usePatientAndAssessments(
    organization.id,
    patientID
  );
  const tabs = [
    { key: 'overview', name: 'Overview', icon: <ChartSquareBarIcon /> },
    {
      key: 'assessments',
      name: `Assessments (${data?.assessments?.length})`,
      icon: <ClipboardCheckIcon />,
    },
    { key: 'downloads', name: 'Downloads', icon: <CloudDownloadIcon /> },
  ];

  if (isLoading) {
    return <LoadingPatientDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  if (data.assessments.length === 0) {
    return (
      <>
        <PatientPageHeader patient={data.patient} />
        <p className="mb-4">No data has been uploaded for this patient.</p>
        <Button
          name="Go Back"
          icon={<ArrowLeftIcon />}
          onClick={() => {
            history.push('/');
          }}
          isSecondaryStyle
        />
      </>
    );
  }

  return (
    <>
      <PatientPageHeader
        patient={data.patient}
        assessments={data.assessments}
        pdfRef={ref}
        pdfVisible={pdfVisible}
        setPdfVisible={setPdfVisible}
      />

      {/* Show flags if the patient object is flagged */}
      {data.patient.flagged?.is_flagged && (
        <FlagAlert color={data.patient.flagged.color} />
      )}

      {/* Show a tab bar for switching between tabs */}
      <TabView
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      {/* Show content for the selected tab */}
      {currentTab === 'overview' && (
        <div className="w-full overflow-visible">
          <PatientOverview
            patient={data.patient}
            assessments={data.assessments}
          />
        </div>
      )}
      {currentTab === 'assessments' && (
        <AssessmentList patient={data.patient} assessments={data.assessments} />
      )}
      {currentTab === 'downloads' && (
        <PatientDownloads
          patient={data.patient}
          assessments={data.assessments}
          pdfRef={ref}
          pdfVisible={pdfVisible}
          setPdfVisible={setPdfVisible}
        />
      )}

      <Button
        name="Go Back"
        icon={<ArrowLeftIcon />}
        onClick={() => {
          history.push('/');
        }}
        isSecondaryStyle
      />

      {pdfVisible && (
        <div className="pdf" ref={ref}>
          <ResultsPDF patient={data.patient} assessments={data.assessments} />
        </div>
      )}
    </>
  );
};

export default ShowPatient;
