import React from 'react';
import PropTypes from 'prop-types';
import '../../css/modules/ModulesOverview.css';
import ModuleDescription from './ModuleDescription';
import ModuleGraph from './ModuleGraph';
import LatestScore from '../assessments/LatestScore';
import {
  calculateAge,
  totalValidModulesInAssessments,
} from '../../utils/scores';
import { isWithinAgeRange } from '../../utils/norms';

const ModulesOverview = ({ patient, moduleKey, assessments }) => {
  const moduleCount = totalValidModulesInAssessments(assessments, moduleKey);
  const age = calculateAge(new Date(patient.date_of_birth), new Date());
  const isScoreablePatient = isWithinAgeRange(age);

  return (
    <div className="modules-overview">
      <div className="modules-inner-section modules-overview-row">
        <ModuleDescription moduleKey={moduleKey} />
      </div>
      <div className="modules-inner-section modules-graph">
        {moduleCount < 2 && isScoreablePatient && (
          <p className="module-center-description">
            Complete more assessments on this patient to see a visualization of
            their scores.
          </p>
        )}
        {moduleCount >= 2 && isScoreablePatient && (
          <ModuleGraph assessments={assessments} moduleKey={moduleKey} />
        )}
      </div>
      <div className="modules-inner-section">
        <LatestScore
          patient={patient}
          assessments={assessments}
          moduleKey={moduleKey}
        />
      </div>
    </div>
  );
};

ModulesOverview.propTypes = {
  patient: PropTypes.object.isRequired,
  moduleKey: PropTypes.string.isRequired,
  assessments: PropTypes.array.isRequired,
};

export default ModulesOverview;
