import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import '../../css/shared/FormTextField.css';
import Cleave from 'cleave.js/react';
import { convertErrorToReadableText } from '../../utils/format';

const PhoneNumberTextField = ({
  title,
  formKey,
  error,
  defaultValue,
  dataTestID,
  placeholder,
  control,
  setValue,
}) => (
  <div className="form-textfield-grid">
    <label htmlFor={formKey} className="form-label">
      {title}
    </label>
    <div className="form-input">
      <Controller
        control={control}
        name="test"
        render={({ field: { onChange, onBlur } }) => (
          <Cleave
            type="text"
            name={formKey}
            id={formKey}
            className={error ? 'form-textfield-error' : 'form-field'}
            data-testid={dataTestID}
            placeholder={placeholder}
            value={defaultValue}
            options={{
              numericOnly: true,
              blocks: [0, 3, 3, 4],
              delimiters: ['(', ') ', '-'],
            }}
            onChange={(e) => {
              setValue(formKey, e.target.value);
              onChange(e.target.value);
            }}
            onBlur={onBlur}
          />
        )}
      />

      {error && (
        <div className="form-textfield-error">
          <ExclamationCircleIcon className="form-icon" />
        </div>
      )}
      <div className="form-error-container">
        {error && (
          <span className="form-error-text">
            {convertErrorToReadableText(error.message)}
          </span>
        )}
      </div>
    </div>
  </div>
);

PhoneNumberTextField.defaultProps = {
  error: undefined,
  defaultValue: undefined,
  dataTestID: undefined,
  placeholder: undefined,
};

PhoneNumberTextField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  error: PropTypes.object,
  defaultValue: PropTypes.any,
  dataTestID: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default PhoneNumberTextField;
