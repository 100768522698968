import { useQuery } from 'react-query';
import { Users } from '../api/users';
import { Organizations } from '../api/organizations';
import { userSchema, organizationListSchema } from '../schemas/schemas';
import validate from '../utils/validate';

const getUser = async (organizationID, userID) => {
  const { data } = await Users.get(organizationID, userID);
  await validate(data, userSchema, 'user', false);
  return data;
};

const getCurrentUserOrganizations = async (currentUser) => {
  if (currentUser.role === 'super_admin') {
    const { data } = await Organizations.listAll();
    await validate(data, organizationListSchema, 'organizations', false);
    return data;
  }
  return { data: currentUser.organizations };
};

function getUserAndCurrentUserOrganizations(
  organizationID,
  userID,
  currentUser
) {
  return Promise.all([
    getUser(organizationID, userID),
    getCurrentUserOrganizations(currentUser),
  ]).then((data) => {
    // Promise.all is guaranteed to return responses in the order we supplied
    // when defining the promise. Therefore, User is always index 0, OrgList is index 1.
    const user = data[0];
    const organizations = data[1];

    // Validate both the user and the organizations
    validate(user, userSchema, 'user', false);
    validate(organizations, organizationListSchema, 'organizations', false);

    return { user, organizations };
  });
}

function useUserAndOrganizations(organizationID, userID, currentUser) {
  return useQuery([organizationID, 'user', userID, 'orgList'], () =>
    getUserAndCurrentUserOrganizations(organizationID, userID, currentUser)
  );
}

export default useUserAndOrganizations;
