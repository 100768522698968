import api from '../config/api';
import { generateQueryString } from '../hooks/usePagination';

export const Organizations = {
  list: (page) =>
    api.get(`/organizations?${generateQueryString(page, 'name', 'asc')}`),
  listAll: () => api.get(`/organizations`),
  get: (organizationID) => api.get(`/organizations/${organizationID}`),
  create: (params) => api.post(`/organizations`, params),
  update: (organizationID, params) =>
    api.put(`/organizations/${organizationID}`, params),
};

export default Organizations;
