import React, { useContext, useEffect } from 'react';
import { SaveIcon } from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../shared/Form';
import DetailItem from '../shared/DetailItem';
import useOrganization from '../../hooks/useOrganization';
import LoadingDetail from '../shared/LoadingDetail';
import useEditOrganization from '../../hooks/useEditOrganization';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import DisplayError from '../shared/DisplayError';
import useCountryStateCity from '../../hooks/useCountryStateCity';
import ControlledFormSelectionField from '../shared/ControlledFormSelectionField';
import FormCheckboxField from '../shared/FormCheckboxField';

const organizationSchema = yup.object().shape({
  city: yup.string().required(),
  state: yup.string().required(),
  country: yup.string().required(),
  emr_enabled: yup.boolean().required(),
});

const EditOrganization = () => {
  const { organizationID } = useParams();
  const history = useHistory();
  const setBannerMessage = useContext(InformationBannerContext);

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    control,
  } = useForm({
    resolver: yupResolver(organizationSchema),
  });

  const { countries, states, cities } = useCountryStateCity(
    watch('country'),
    watch('state'),
    watch('city'),
    setValue,
    getValues
  );

  // API handler
  const mutation = useEditOrganization(organizationID, (response) => {
    setBannerMessage('Updated organization successfully.');
    history.push(`/organizations/${response.data.id}`);
  });

  const {
    data: organization,
    isLoading,
    isError,
    error,
  } = useOrganization(organizationID);

  useEffect(() => {
    if (organization) {
      setValue('country', organization.country);
      setValue('state', organization.state);
      setValue('city', organization.city);
    }
  }, [organization]);

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  return (
    <>
      <Form
        title="Edit Organization"
        description="Update details about your organization below."
        onSubmit={handleSubmit((data) => mutation.mutate(data))}
        submitText="Update Organization"
        submitIcon={<SaveIcon />}
        cancelLink={`/organizations/${organizationID}`}
        isPosting={mutation.isLoading}
        isError={mutation.isError}
        error={mutation.error}
        validationErrors={errors}
      >
        <DetailItem
          title="Organization Name"
          value={organization.name}
          dataTestID="name"
        />
        <ControlledFormSelectionField
          error={errors.country}
          title="Country"
          formKey="country"
          options={countries}
          control={control}
          defaultValue={organization.country}
          setValue={setValue}
        />
        <ControlledFormSelectionField
          error={errors.state}
          title="State"
          formKey="state"
          options={states}
          control={control}
          defaultValue={organization.state}
          setValue={setValue}
        />
        <ControlledFormSelectionField
          error={errors.city}
          title="City"
          formKey="city"
          options={cities}
          control={control}
          defaultValue={organization.city}
          setValue={setValue}
        />
        <FormCheckboxField
          register={register}
          error={errors.emr_enabled}
          title="EMR Enabled"
          formKey="emr_enabled"
          dataTestID="emr_enabled"
          defaultValue={organization.emr_enabled}
        />
      </Form>
    </>
  );
};

export default EditOrganization;
