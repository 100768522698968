import { useQuery } from 'react-query';
import { Users } from '../api/users';
import { userListSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function useUsers(organizationID, page) {
  return useQuery([organizationID, 'users', 'list', page], async () => {
    const { data } = await Users.list(organizationID, page);
    validate(data, userListSchema, 'users', false);
    return data;
  });
}

export default useUsers;
