/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { InformationBannerContext } from './contexts/InformationBannerContext';
import { useAuth } from './hooks/useAuth';
import './css/shared/App.css';
import InformationalBanner from './components/shared/InformationBanner';
import environment from './config/environment';
import StyledAppRoute from './components/authentication/StyledAppRoute';
import AuthenticatedAppRoute from './components/authentication/AuthenticatedAppRoute';
import SuperAdminAuthenticatedAppRoute from './components/authentication/SuperAdminAppRoute';
import AdminAuthenticatedAppRoute from './components/authentication/AdminAppRoute';
import Footer from './components/shared/Footer';
import Loading from './components/shared/Loading';
import CacheBuster from './components/shared/CacheBuster';
import packageJson from '../package.json';
import PatientList from './components/patients/PatientList';
import ShowPatient from './components/patients/ShowPatient';
import EditPatient from './components/patients/EditPatient';
import AddAuditLog from './components/audit-logs/CreateAuditLog';
import RequestList from './components/requests/RequestList';
import UnlockUser from './components/authentication/UnlockUser';
import CreateResearchCSV from './components/research-csv/CreateResearchCSV';

// We need to import Login, even if we're not using it in this component,
// because it needs to be part of the main bundle. If we don't, the image will be broken
// until the user logs in and loads the other bundle.
// eslint-disable-next-line no-unused-vars
import Login from './components/authentication/Login';
import PasswordExpirationBanner from './components/shared/PasswordExpirationBanner';
import { daysUntilPasswordExpires } from './utils/passwordExpiration';
import AddDevice from './components/devices/AddDevice';
import DeviceList from './components/devices/DeviceList';
import ShowDevice from './components/devices/ShowDevice';
import AddOrganization from './components/organizations/AddOrganization';
import OrganizationList from './components/organizations/OrganizationList';
import AccountRecovery from './components/authentication/AccountRecovery';
import ShowOrganization from './components/organizations/ShowOrganization';
import EditOrganization from './components/organizations/EditOrganization';
import MissingResource from './components/shared/MissingResource';
import NoDataPageHeader from './components/shared/NoDataPageHeader';
import UserList from './components/users/UserList';
import ShowUser from './components/users/ShowUser';
import EditUser from './components/users/EditUser';
import AddUser from './components/users/AddUser';
import ConfirmUser from './components/users/ConfirmUser';
import ResetPassword from './components/users/ResetPassword';
import AccountSettings from './components/users/AccountSettings';
import Navigation from './components/navigation/Navigation';
import IdleTracker from './components/shared/IdleTracker';

const configureSentry = () => {
  if (environment.disableSentry) {
    console.warn('Sentry disabled by debug flag');
  } else if (environment.sentryDSN === undefined) {
    console.warn('Missing Sentry DSN');
  } else {
    Sentry.init({ dsn: environment.sentryDSN, release: packageJson.version });
  }
};

configureSentry();

const App = () => {
  const { user, organization, signout, setDidTriggerIdleTimer } = useAuth();
  const [bannerMessage, setBannerMessage] = useState();
  const [didResetPassword, setDidChangePassword] = useState(false);

  if (environment.apiURL === undefined) {
    // Missing an API url is an unrecoverable error.
    // Display an error page with an explanation.
    return (
      <NoDataPageHeader
        title="Invalid Configuration"
        description={`The app is missing the API URL environment variable. Configuration: ${JSON.stringify(
          environment
        )}`}
      />
    );
  }

  let daysUntilPasswordExpiration;
  if (user) {
    daysUntilPasswordExpiration = daysUntilPasswordExpires(user);
  }

  return (
    <Sentry.ErrorBoundary fallback="An error has occurred">
      <InformationBannerContext.Provider value={setBannerMessage}>
        <Router>
          {user && (
            <IdleTracker
              user={user}
              signout={signout}
              setBannerMessage={setBannerMessage}
              setDidTriggerIdleTimer={setDidTriggerIdleTimer}
            />
          )}
          <Suspense fallback={<Loading />}>
            {user && organization && <Navigation />}
            <InformationalBanner
              bannerMessage={bannerMessage}
              setBannerMessage={setBannerMessage}
            />

            {daysUntilPasswordExpiration &&
              daysUntilPasswordExpiration <= 10 &&
              !didResetPassword && (
                <PasswordExpirationBanner
                  daysRemaining={daysUntilPasswordExpiration}
                />
              )}

            <Switch>
              {/* Authentication */}
              <Route path="/account-recovery" exact>
                <AccountRecovery />
              </Route>

              {/* Landing page (Patient List) */}
              <AuthenticatedAppRoute path="/" exact>
                <PatientList />
              </AuthenticatedAppRoute>

              {/* Organizations */}
              <SuperAdminAuthenticatedAppRoute path="/organizations" exact>
                <OrganizationList />
              </SuperAdminAuthenticatedAppRoute>
              <SuperAdminAuthenticatedAppRoute path="/organizations/new" exact>
                <AddOrganization />
              </SuperAdminAuthenticatedAppRoute>
              <SuperAdminAuthenticatedAppRoute
                path="/organizations/:organizationID"
                exact
              >
                <ShowOrganization />
              </SuperAdminAuthenticatedAppRoute>
              <SuperAdminAuthenticatedAppRoute path="/organizations/:organizationID/edit">
                <EditOrganization />
              </SuperAdminAuthenticatedAppRoute>

              {/* Devices */}
              <AdminAuthenticatedAppRoute path="/devices" exact>
                <DeviceList />
              </AdminAuthenticatedAppRoute>
              <AdminAuthenticatedAppRoute path="/devices/new" exact>
                <AddDevice />
              </AdminAuthenticatedAppRoute>
              <AdminAuthenticatedAppRoute path="/devices/:deviceID">
                <ShowDevice />
              </AdminAuthenticatedAppRoute>

              {/* Users */}
              <AdminAuthenticatedAppRoute path="/users" exact>
                <UserList />
              </AdminAuthenticatedAppRoute>
              <AdminAuthenticatedAppRoute path="/users/new" exact>
                <AddUser />
              </AdminAuthenticatedAppRoute>
              <AdminAuthenticatedAppRoute path="/users/:userID" exact>
                <ShowUser />
              </AdminAuthenticatedAppRoute>
              <AuthenticatedAppRoute path="/users/:userID/edit" exact>
                <EditUser />
              </AuthenticatedAppRoute>
              <AuthenticatedAppRoute path="/account" exact>
                <AccountSettings setDidChangePassword={setDidChangePassword} />
              </AuthenticatedAppRoute>

              {/* Patients */}
              <AuthenticatedAppRoute path="/patients/:patientID" exact>
                <ShowPatient />
              </AuthenticatedAppRoute>
              <AuthenticatedAppRoute path="/patients" exact>
                <PatientList />
              </AuthenticatedAppRoute>
              <AuthenticatedAppRoute path="/patients/:patientID/edit" exact>
                <EditPatient />
              </AuthenticatedAppRoute>

              {/* Research CSV's */}
              <AdminAuthenticatedAppRoute path="/research-csv" exact>
                <CreateResearchCSV />
              </AdminAuthenticatedAppRoute>

              {/* Request List */}
              <AdminAuthenticatedAppRoute path="/requests" exact>
                <RequestList />
              </AdminAuthenticatedAppRoute>

              {/* Audit Logs */}
              <AdminAuthenticatedAppRoute path="/audit-logs" exact>
                <AddAuditLog />
              </AdminAuthenticatedAppRoute>

              {/* Pages linked from email */}
              {/* Confirm User Account */}
              <Route path="/confirmation" exact>
                <ConfirmUser />
              </Route>
              <Route path="/reset-password" exact>
                <ResetPassword />
              </Route>

              <Route path="/locked" exact>
                <UnlockUser />
              </Route>

              {/* 404 */}
              <StyledAppRoute path="*">
                <MissingResource />
              </StyledAppRoute>
            </Switch>

            <CacheBuster>
              {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!isLatestVersion) {
                  refreshCacheAndReload();
                }

                return <Footer />;
              }}
            </CacheBuster>
          </Suspense>
        </Router>
      </InformationBannerContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
