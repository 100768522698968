import api from '../config/api';
import { generateQueryString } from '../hooks/usePagination';

export const Devices = {
  list: (organizationID, page) =>
    api.get(
      `/organizations/${organizationID}/devices?${generateQueryString(
        page,
        'last_used_at',
        'desc'
      )}`
    ),
  get: (organizationID, deviceID) =>
    api.get(`/organizations/${organizationID}/devices/${deviceID}`),
  create: (organizationID, params) =>
    api.post(`/organizations/${organizationID}/devices`, params),
  deactivate: (organizationID, deviceID) =>
    api.post(`/organizations/${organizationID}/devices/${deviceID}/deactivate`),
  regenerate: (organizationID, deviceID) =>
    api.post(
      `/organizations/${organizationID}/devices/${deviceID}/regenerate-keys`
    ),
};

export default Devices;
