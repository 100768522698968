import { useMutation } from 'react-query';
import { Users } from '../api/users';

function useChangePassword(userID, successCallback) {
  return useMutation(
    (data) =>
      Users.changePassword(userID, {
        current_password: data.current_password,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
      }),
    {
      onSuccess: async (response) => {
        successCallback(response);
      },
    }
  );
}

export default useChangePassword;
