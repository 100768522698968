import React from 'react';
import PropTypes from 'prop-types';
import { SortDescendingIcon, EyeIcon } from '@heroicons/react/outline';
import Button from '../shared/Button';
import '../../css/shared/Table.css';
import '../../css/organizations/OrganizationTable.css';
import { displayableDate, truncate } from '../../utils/format';

const OrganizationTable = ({ organizations }) => (
  <div className="table">
    <div className="table-inner">
      <div className="table-wrapper">
        <div className="table-wrapper-shadow">
          <table>
            <thead>
              <tr>
                <th scope="col" className="flex">
                  Name <SortDescendingIcon className="sort-icon" />
                </th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">Country</th>
                <th scope="col">Created</th>
                <th className="table-th-buttons" scope="col">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((organization) => (
                <tr key={organization.id}>
                  <td className="organization-row-name">
                    {truncate(organization.name, 25)}
                  </td>
                  <td className="organization-row-data">
                    {truncate(organization.city, 25)}
                  </td>
                  <td className="organization-row-data">
                    {truncate(organization.state, 25)}
                  </td>
                  <td className="organization-row-data">
                    {truncate(organization.country, 25)}
                  </td>
                  <td className="organization-row-data">
                    {displayableDate(organization.created_at)}
                  </td>
                  <td className="patient-row-data text-center">
                    <Button
                      icon={<EyeIcon />}
                      link={`/organizations/${organization.id}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

OrganizationTable.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OrganizationTable;
