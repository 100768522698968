import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

// A wrapper for <Route> that renders an "Unauthorized"
// page if the user isn't authorized to access a given page
function StyledAppRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        if (auth.isLoading) {
          return <></>;
        }

        return <div className="app-container">{children}</div>;
      }}
    />
  );
}

StyledAppRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StyledAppRoute;
