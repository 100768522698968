import React from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@heroicons/react/solid';
import { useAuth } from '../../hooks/useAuth';
import Button from './Button';
import NoDataPageHeader from './NoDataPageHeader';
import PageHeader from './PageHeader';
import SkeletonTable from './SkeletonTable';
import Pagination from './Pagination';
import DisplayError from './DisplayError';

export default function ListPage({
  title,
  description,
  noObjectsTitle,
  noObjectsDescription,
  newObjectButtonTitle,
  newObjectLink,
  table,
  query,
  page,
  setPage,
  hideOrganizationName,
}) {
  const { organization } = useAuth();

  // Loading state
  if (query.isLoading) {
    return (
      <>
        <ListPageHeader
          title={title}
          description={description}
          hideOrganizationName={hideOrganizationName}
          refreshing
        />
        <SkeletonTable />
      </>
    );
  }

  // Error state
  if (query.isError) {
    return <DisplayError error={query.error} />;
  }

  // Empty state
  if (
    query.data === undefined ||
    query.data.data === undefined ||
    query.data.data.length === 0
  ) {
    return (
      <>
        {newObjectButtonTitle && (
          <NoDataPageHeader
            title={noObjectsTitle}
            subtitle={hideOrganizationName ? '' : organization.name}
            description={noObjectsDescription}
            refreshing={query.isFetching}
            button={
              <Button
                name={newObjectButtonTitle}
                icon={<PlusIcon />}
                className="mx-auto"
                link={newObjectLink}
              />
            }
          />
        )}
        {newObjectButtonTitle === undefined && (
          <NoDataPageHeader
            title={noObjectsTitle}
            subtitle={hideOrganizationName ? '' : organization.name}
            description={noObjectsDescription}
            refreshing={query.isFetching}
          />
        )}
      </>
    );
  }

  // Populated state
  return (
    <>
      <ListPageHeader
        title={title}
        description={description}
        refreshing={query.isFetching}
        hideOrganizationName={hideOrganizationName}
      />
      <div className="mb-5 text-right">
        {newObjectButtonTitle && (
          <Button
            name={newObjectButtonTitle}
            icon={<PlusIcon />}
            className=""
            link={newObjectLink}
          />
        )}
      </div>
      {table(query.data.data)}
      {query.data.paging && (
        <Pagination
          page={page}
          setPage={(p) => {
            window.scrollTo(0, 0);
            setPage(p);
          }}
          pageCount={query.data.paging.page_count}
          isPreviousData={query.isPreviousData}
        />
      )}
    </>
  );
}

ListPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noObjectsTitle: PropTypes.string.isRequired,
  noObjectsDescription: PropTypes.string.isRequired,
  newObjectButtonTitle: PropTypes.string,
  newObjectLink: PropTypes.string,
  table: PropTypes.func.isRequired, // Function that accepts data and generates a table component
  query: PropTypes.object.isRequired, // React-query useQuery object
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  hideOrganizationName: PropTypes.bool,
};

ListPage.defaultProps = {
  hideOrganizationName: false,
  newObjectButtonTitle: undefined,
  newObjectLink: undefined,
};

const ListPageHeader = ({
  title,
  description,
  refreshing,
  hideOrganizationName,
}) => {
  const { organization } = useAuth();
  return (
    <PageHeader
      title={title}
      subtitle={hideOrganizationName ? '' : organization.name}
      description={description}
      refreshing={refreshing}
    />
  );
};

ListPageHeader.defaultProps = {
  refreshing: false,
  hideOrganizationName: false,
};

ListPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  refreshing: PropTypes.bool,
  hideOrganizationName: PropTypes.bool,
};
