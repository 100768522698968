import api from '../config/api';
import { generateQueryString } from '../hooks/usePagination';

export const Patients = {
  list: (organizationID, page) =>
    api.get(
      `/organizations/${organizationID}/patients?${generateQueryString(
        page,
        'last_assessment_at',
        'desc'
      )}`
    ),
  search: (organizationID, searchTerm, searchAttribute) =>
    api.get(
      `/organizations/${organizationID}/search/patients?${searchAttribute}=${searchTerm}&sort=last_assessment_at&order=desc`
    ),
  get: (organizationID, patientID) =>
    api.get(`/organizations/${organizationID}/patients/${patientID}`),
  update: (organizationID, patientID, params) =>
    api.put(`/organizations/${organizationID}/patients/${patientID}`, params),
};

export default Patients;
