import React from 'react';
import PropTypes from 'prop-types';
import '../../css/shared/ErrorAlert.css';
import { Link } from 'react-router-dom';
import { SupportEmail } from '../../utils/constants';

const LoginErrorAlert = ({ email }) => (
  <div className="mt-14">
    <div className="error-alert" role="alert">
      <p className="font-bold">Unable to Log In</p>
      <p>Please provide a valid email and password combination.</p>
      <br />
      <p>
        If this problem persists, you may try{' '}
        <Link
          to={`/account-recovery?email=${email}`}
          className="underline font-bold"
        >
          recovering your account
        </Link>{' '}
        or contacting{' '}
        <a className="underline font-bold" href={`mailto:${SupportEmail}`}>
          Support
        </a>
        .
      </p>
    </div>
  </div>
);

LoginErrorAlert.defaultProps = {
  email: '',
};

LoginErrorAlert.propTypes = {
  email: PropTypes.string,
};

export default LoginErrorAlert;
