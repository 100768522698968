import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LoginIcon } from '@heroicons/react/outline';
import ErrorAlert from '../shared/ErrorAlert';
import NoDataPageHeader from '../shared/NoDataPageHeader';
import { useAuth } from '../../hooks/useAuth';
import qr8Logo from '../../images/qr8logo.png';
import '../../css/authentication/Login.css';
import '../../css/shared/FormTextField.css';
import '../../css/users/ConfirmUser.css';
import Button from '../shared/Button';
import { capitalize } from '../../utils/format';
import useCheckResetPassword from '../../hooks/useCheckResetPassword';
import { resetPasswordSchema } from '../../utils/passwords';
import UserAccountBackground from './UserAccountBackground';

const queryString = require('query-string');

const ResetPassword = () => {
  // Get the current user to make sure they're not already logged in
  const auth = useAuth();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  // Parse the reset password token from the parameters
  // eslint-disable-next-line no-restricted-globals
  const params = queryString.parse(location.search);

  // Check that the token is valid before loading the form
  const { data, isLoading, isError, error } = useCheckResetPassword(
    params.resetPasswordToken
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const history = useHistory();
  const onSubmit = ({ password, passwordConfirmation }) => {
    auth.resetPassword(
      data.id,
      password,
      passwordConfirmation,
      params.resetPasswordToken,
      () => {
        history.push('/?message=recovered');
      }
    );
  };

  // Check if we're already logged in
  if (auth.user !== undefined) {
    return (
      <div className="app-container">
        <ErrorAlert
          title="Reset Password Error"
          message="You are already logged in"
        />
      </div>
    );
  }

  // Check if the token is missing
  if (params.resetPasswordToken === undefined) {
    return (
      <div className="app-container">
        <ErrorAlert
          title="Invalid Link"
          message="The link you followed is no longer valid"
        />
      </div>
    );
  }

  // Check if we're loading the "Check Reset Password Token" request
  if (isLoading) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <NoDataPageHeader
            title="Reset Password Request"
            description="Please wait while we load your user account."
            refreshing
          />
        </div>
      </div>
    );
  }

  // Check if the CheckResetPasswordToken request failed
  if (isError) {
    return (
      <div className="app-container">
        <ErrorAlert title="Error" message={error.message} />
      </div>
    );
  }

  return (
    <div className="login-wrapper">
      <div className="login-inner-wrapper">
        <div className="login-left">
          <div>
            <img className="login-image" src={qr8Logo} alt="Qr8 Health" />
            <h2 className="login-slogan">
              Pioneering patient assessment tools for the measurement of
              neurological and motor function.
            </h2>
            <h2 className="instructions">
              {query.get('expired') ? 'Your password has expired. ' : ''}
              Please enter your new password below.
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label
                    htmlFor="password"
                    className={
                      errors.password ? 'login-label-error' : 'login-label'
                    }
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      data-testid="PasswordTextField"
                      required
                      className={
                        errors.password
                          ? 'login-textfield-error'
                          : 'login-textfield'
                      }
                      {...register('password')}
                    />
                    <div className="login-error">
                      <span>
                        {errors.password && capitalize(errors.password.message)}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="passwordConfirmation"
                    className={
                      errors.passwordConfirmation
                        ? 'login-label-error'
                        : 'login-label'
                    }
                  >
                    Password Confirmation
                  </label>
                  <div className="mt-1">
                    <input
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type="password"
                      data-testid="PasswordConfirmationTextField"
                      required
                      className={
                        errors.passwordConfirmation
                          ? 'login-textfield-error'
                          : 'login-textfield'
                      }
                      {...register('passwordConfirmation')}
                    />
                    <div className="login-error">
                      {errors.passwordConfirmation && (
                        <span>Password confirmation must match password.</span>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <Button
                    name={
                      auth.confirmUserMutation.isLoading
                        ? 'Please wait...'
                        : 'Update Password'
                    }
                    additionalStyle="login-button"
                    icon={<LoginIcon />}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={auth.confirmUserMutation.isLoading}
                    disabled={auth.confirmUserMutation.isLoading}
                  />
                </div>

                <div className="text-sm text-center">
                  <Link to="/" className="login-recovery">
                    Log In
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="login-right">
        <UserAccountBackground />
      </div>
    </div>
  );
};

export default ResetPassword;
