import * as yup from 'yup';

export const organizationSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  country: yup.string().required(),
  emr_enabled: yup.bool().required(),
  subdomain: yup.string().required(),
  created_at: yup.string().required(),
});

export const organizationListSchema = yup.object().shape({
  data: yup.array().of(organizationSchema),
});

export const patientSchema = yup.object().shape({
  id: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  date_of_birth: yup.string().required(),
  mrn: yup.string().required(),
  sex: yup.string(),
  education: yup.number(),
  races: yup.array().of(yup.string()),
  last_assessment_date: yup.string(),
});

export const patientListSchema = yup.object().shape({
  data: yup.array().of(patientSchema).notRequired(),
});

export const userSchema = yup.object().shape({
  id: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().required(),
  role: yup
    .string()
    .required()
    .test((value) => ['user', 'admin', 'super_admin'].includes(value)),
  phone: yup.string(),
  active: yup.bool().required(),
  locked: yup.bool().required(),
  email_confirmed: yup.bool().required(),
  current_organization_id: yup.string().required(),
  organizations: yup.array().of(organizationSchema),
  last_login_date: yup.string().required(),
});

export const userListSchema = yup.object().shape({
  data: yup.array().of(userSchema).notRequired(),
});

export const moduleSchema = yup.object().shape({
  key: yup.string().required(),
  // Canceled modules will not have score properties
  zscore: yup.number(),
  raw_score: yup.number(),
  percentile: yup.number(),
});

export const assessmentSchema = yup.object().shape({
  request_id: yup.string().required(),
  created_at: yup.string().required(),
  user: userSchema.required(),
  education_at_assessment: yup.number().required(),
  modules: yup.array().of(moduleSchema).notRequired(),
});

export const assessmentListSchema = yup.object().shape({
  data: yup.array().of(assessmentSchema).notRequired(),
});

export const deviceSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  serial_number: yup.string().required(),
  active: yup.bool().required(),
  confirmed: yup.bool().required(),
  device_token: yup.string(),
  last_used: yup.string().required(),
  app_version: yup.string(),
  os_version: yup.string(),
  qr_code_expiration: yup.string(),
  type: yup.string().required(),
  qr_code: yup
    .object({
      organization_id: yup.string().required(),
      organization_name: yup.string().required(),
      device_token: yup.string().required(),
      device_id: yup.string().required(),
    })
    .default(undefined),
});

export const devicesSchema = yup.object().shape({
  data: yup.array().of(deviceSchema),
});

export const requestSchema = yup.object().shape({
  id: yup.string().required(),
  assessment_id: yup.string(),
  code: yup.number().required(),
  description: yup.string().required(),
  created_at: yup.string().required(),
});

export const requestListSchema = yup.object().shape({
  data: yup.array().of(requestSchema),
});
