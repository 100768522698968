import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/navigation/SuperAdminMenuItem.css';
import PropTypes from 'prop-types';

const SuperAdminMenuItem = ({
  icon,
  name,
  description,
  link,
  onClick,
  dismiss,
}) => {
  const history = useHistory();

  const styledIcon = React.cloneElement(icon, {
    className: 'super-admin-menu-item-icon',
  });

  const handleClick = () => {
    if (link) {
      history.push(link);
    }
    if (onClick) {
      onClick();
    }
    dismiss();
  };

  return (
    <li className="super-admin-menu-item">
      <button type="button" className="super-admin-link" onClick={handleClick}>
        <div className="super-admin-content">
          {styledIcon}{' '}
          <p className="super-admin-p">
            {name}{' '}
            <span className="super-admin-p-description">{description}</span>
          </p>
        </div>
      </button>
    </li>
  );
};

SuperAdminMenuItem.defaultProps = {
  onClick: () => {},
  link: undefined,
};

SuperAdminMenuItem.propTypes = {
  icon: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default SuperAdminMenuItem;
