import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import Alert from './Alert';

const InformationalBanner = ({ bannerMessage, setBannerMessage }) => {
  const location = useLocation();
  const [locationChangeCount, setLocationChangeCount] = useState(0);

  useEffect(() => {
    if (bannerMessage !== undefined) {
      setLocationChangeCount(locationChangeCount + 1);
      if (locationChangeCount > 0) {
        setBannerMessage(undefined);
        setLocationChangeCount(0);
      }
    }
  }, [location]);

  if (bannerMessage) {
    return (
      <div className="app-container mb-16 mt-10">
        <Alert
          message={bannerMessage}
          dismiss={() => {
            setBannerMessage(undefined);
          }}
        />
      </div>
    );
  }

  return <></>;
};

InformationalBanner.defaultProps = {
  bannerMessage: undefined,
  setBannerMessage: undefined,
};

InformationalBanner.propTypes = {
  bannerMessage: PropTypes.string,
  setBannerMessage: PropTypes.func,
};

export default InformationalBanner;
