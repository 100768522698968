import PropTypes from 'prop-types';
import {
  ageLowerBound,
  ageUpperBound,
  isAboveUpperAgeBound,
  isBelowLowerAgeBound,
  isWithinAgeRange,
} from '../../utils/norms';
import { calculateAge } from '../../utils/scores';

export const NoScoresWarning = ({ patient, styleName }) => {
  const age = calculateAge(new Date(patient.date_of_birth), new Date());

  return (
    <>
      {isBelowLowerAgeBound(age) && (
        <p className={styleName}>
          The patient is under {ageLowerBound} years old and and will not have
          scores calculated.
        </p>
      )}
      {isAboveUpperAgeBound(age) && (
        <p className={styleName}>
          The patient is greater than or equal to {ageUpperBound} years old and
          and will not have scores calculated.
        </p>
      )}
      {isWithinAgeRange(age) && (
        <p className={styleName}>
          There are no scored tests in this assessment.
        </p>
      )}
    </>
  );
};

NoScoresWarning.defaultProps = {
  styleName: '',
};

NoScoresWarning.propTypes = {
  patient: PropTypes.object.isRequired,
  styleName: PropTypes.string,
};
