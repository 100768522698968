import { useMutation, useQueryClient } from 'react-query';
import { Organizations } from '../api/organizations';

function useAddOrganization(successCallback) {
  const queryClient = useQueryClient();
  return useMutation((data) => Organizations.create(data), {
    onSuccess: async (response) => {
      queryClient.invalidateQueries();
      successCallback(response);
    },
  });
}

export default useAddOrganization;
