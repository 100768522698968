import React from 'react';
import '../../css/shared/NoDataPageHeader.css';
import PropTypes from 'prop-types';
import empty from '../../images/emptystate.png';
import ProgressSpinner from './ProgressSpinner';

const NoDataPageHeader = ({
  title,
  subtitle,
  description,
  refreshing,
  button,
}) => (
  <div className="no-data-page-header">
    <ProgressSpinner styleClass="mx-auto mb-5" refreshing={refreshing} />
    <p className="no-data-page-header-subtitle">{subtitle}</p>
    <h1 className="no-data-page-header-title">{title}</h1>
    {typeof description === 'string' && (
      <p className="no-data-page-header-description">{description}</p>
    )}
    {typeof description !== 'string' && description}
    {button && button}
    <img src={empty} className="no-data-page-header-img" alt={description} />
  </div>
);

NoDataPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  refreshing: PropTypes.bool,
  button: PropTypes.element,
};

NoDataPageHeader.defaultProps = {
  refreshing: false,
  button: undefined,
  subtitle: '',
};

export default NoDataPageHeader;
