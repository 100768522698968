import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import '../../css/shared/FormTextField.css';
import { convertErrorToReadableText } from '../../utils/format';

const ControlledFormSelectionField = ({
  title,
  formKey,
  options,
  error,
  control,
}) => (
  <div className="form-textfield-grid">
    <label htmlFor={formKey} className="form-label">
      {title}
    </label>
    <div className="form-input">
      <Controller
        control={control}
        name={formKey}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <select
              id={formKey}
              name={formKey}
              data-testid={formKey}
              className="form-field"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              {options.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.title}
                </option>
              ))}
            </select>
          </>
        )}
      />

      {error && (
        <div className="form-textfield-error">
          <ExclamationCircleIcon className="form-icon" />
        </div>
      )}

      <div className="form-error-container">
        {error && (
          <span className="form-error-text">
            {convertErrorToReadableText(error.message)}
          </span>
        )}
      </div>
    </div>
  </div>
);

ControlledFormSelectionField.defaultProps = {
  error: undefined,
};

ControlledFormSelectionField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  error: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.object.isRequired,
};

export default ControlledFormSelectionField;
