import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { Link, useLocation } from 'react-router-dom';

const expirationText = (days) => {
  const roundedDays = Math.round(days);
  if (roundedDays === 0) {
    return 'Your password will expire today. ';
  }
  if (roundedDays === 1) {
    return 'Your password will expire tomorrow. ';
  }
  return `Your password will expire in ${roundedDays} days. `;
};

const PasswordExpirationBanner = ({ daysRemaining }) => {
  const location = useLocation();

  return (
    <div className="app-container mb-16 mt-10">
      <div className="rounded-md bg-yellow-50 p-4 mt-10 -mb-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-yellow-600">
              {expirationText(daysRemaining)}
              {location.pathname !== '/account' && (
                <>
                  <Link
                    className="text-yellow-600 font-bold underline hover:text-yellow-800"
                    to="/account"
                  >
                    Click here
                  </Link>{' '}
                  to change it now.
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordExpirationBanner.propTypes = {
  daysRemaining: PropTypes.number.isRequired,
};

export default PasswordExpirationBanner;
