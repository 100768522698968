import React, { useState } from 'react';
import Pdf from 'react-to-pdf';
import PropTypes from 'prop-types';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid';
import { ClipboardCopyIcon, DownloadIcon } from '@heroicons/react/outline';
import Button from '../shared/Button';
import '../../css/shared/Table.css';
import '../../css/patients/PatientTable.css';
import '../../css/patients/PatientDownloads.css';
import { generateResultText } from '../../utils/scores';
import { generatePdfFilename } from '../../utils/pdf';

const PatientDownloads = ({
  patient,
  assessments,
  pdfRef,
  pdfVisible,
  setPdfVisible,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [copied, setCopied] = useState(false);
  const patientText = generateResultText(patient, assessments);
  return (
    <div className="table patient-downloads">
      <div className="table-inner">
        <div className="table-wrapper">
          <div className="table-wrapper-shadow">
            <table>
              <thead>
                <tr>
                  <th scope="col">File</th>
                  <th scope="col">Description</th>
                  <th scope="col">Type</th>
                  <th className="table-th-buttons" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-bold">Patient Data PDF (Printable)</td>
                  <td>A printable summary of all data for the patient.</td>
                  <td>PDF</td>
                  <td className="text-center table-th-buttons">
                    <Pdf
                      targetRef={pdfRef}
                      filename={generatePdfFilename()}
                      scale={0.8}
                      x={10}
                      y={10}
                      onComplete={() => {
                        setPdfVisible(false);
                      }}
                    >
                      {({ toPdf }) => (
                        <Button
                          disabled={pdfVisible}
                          icon={<DownloadIcon />}
                          isLoading={pdfVisible}
                          onClick={() => {
                            setPdfVisible(true);

                            // State updates do not happen instantly,
                            // so we need to wait for a second.
                            //
                            // This can be improved by implementing a useEffect in ShowPatient.
                            setTimeout(() => {
                              toPdf();
                            }, 1800);
                          }}
                        />
                      )}
                    </Pdf>
                  </td>
                </tr>
                <tr>
                  <td className="font-bold">Patient Data (Text)</td>
                  <td>
                    A text-based summary of patient data that can be pasted into
                    an EMR.
                  </td>
                  <td>TXT</td>
                  <td className="text-center table-th-buttons">
                    <Button
                      icon={expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                      onClick={() => {
                        setExpanded(!expanded);
                      }}
                    />
                  </td>
                </tr>
                {expanded && (
                  <tr>
                    <td colSpan={4}>
                      <textarea
                        className="patient-downloads-text"
                        rows="16"
                        cols="90"
                        name="description"
                        readOnly
                        value={patientText}
                      />
                      <br />
                      <Button
                        additionalStyle="copy-to-clipboard"
                        name={
                          copied ? 'Copied to Clipboard!' : 'Copy to Clipboard'
                        }
                        icon={copied ? <CheckIcon /> : <ClipboardCopyIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(patientText);
                          setCopied(true);
                        }}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

PatientDownloads.defaultProps = {
  pdfRef: undefined,
  pdfVisible: undefined,
  setPdfVisible: () => {},
};

PatientDownloads.propTypes = {
  patient: PropTypes.object.isRequired,
  assessments: PropTypes.arrayOf(PropTypes.object).isRequired,
  pdfRef: PropTypes.object,
  pdfVisible: PropTypes.bool,
  setPdfVisible: PropTypes.func,
};

export default PatientDownloads;
