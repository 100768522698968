import { useQuery } from 'react-query';
import { Devices } from '../api/devices';
import { deviceSchema } from '../schemas/schemas';
import validate from '../utils/validate';

const useDevice = (organizationID, deviceID, options) =>
  useQuery(
    [organizationID, deviceID, 'devices', 'show'],
    async () => {
      const { data } = await Devices.get(organizationID, deviceID);
      await validate(data, deviceSchema, 'device', false);
      return data;
    },
    options
  );

export default useDevice;
