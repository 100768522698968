import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InformationalDialog from './InformationalDialog';
import '../../css/shared/FormTextField.css';
import '../../css/shared/FormMultiSelectionField.css';

const FormMultiSelectionField = ({
  title,
  formKey,
  options,
  error,
  register,
  errorDescription,
  defaultValues,
  disabledOption,
  setValue,
  disabledExplanation,
  warningDialogTitle,
  warningDialogDescription,
}) => {
  const selectAll = () => {
    setValue(
      'organization_ids',
      options.map((o) => o.value)
    );
  };
  const selectNone = () => {
    setValue('organization_ids', [disabledOption]);
  };
  const [showDisabledExplanation, setShowDisabledExplanation] = useState(false);

  useEffect(() => {
    setValue('organization_ids', defaultValues);
  }, []);

  return (
    <>
      <InformationalDialog
        title={warningDialogTitle}
        message={warningDialogDescription}
        open={showDisabledExplanation}
        setOpen={setShowDisabledExplanation}
      />
      <div className="form-textfield-grid">
        <label htmlFor={formKey} className="form-label">
          {title}
        </label>
        <div className="form-input">
          <div className="select-buttons">
            <button type="button" onClick={selectAll}>
              Select All
            </button>
            <br />
            <button type="button" onClick={selectNone}>
              Select None
            </button>
          </div>

          <fieldset>
            {options
              .sort((o1, o2) =>
                o1.title.toLowerCase().localeCompare(o2.title.toLowerCase())
              )
              .map((o) => (
                <div className="flex" key={o.value}>
                  <input
                    className={`mr-2 mb-2 form-checkbox ${
                      disabledOption === o.value &&
                      'disabled-multiselect-checkbox'
                    }`}
                    type="checkbox"
                    key={o.value}
                    name={o.value}
                    value={o.value}
                    id={o.value}
                    onClick={(e) => {
                      if (disabledOption === o.value) {
                        e.target.checked = true;
                        setShowDisabledExplanation(true);
                      }
                    }}
                    defaultChecked={defaultValues.includes(o.value)}
                    {...register(formKey, { required: 'This is required' })}
                  />
                  <label className="mt-1" htmlFor={o.value}>
                    {o.title}{' '}
                    {disabledOption === o.value && `(${disabledExplanation})`}
                  </label>
                </div>
              ))}
          </fieldset>

          <div className="form-error-container">
            {error && (
              <span className="form-error-text">{errorDescription}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

FormMultiSelectionField.defaultProps = {
  error: undefined,
  defaultValues: [],
  disabledOption: undefined,
};

FormMultiSelectionField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  error: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  register: PropTypes.func.isRequired,
  errorDescription: PropTypes.string.isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.string),
  disabledOption: PropTypes.string,
  disabledExplanation: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  warningDialogTitle: PropTypes.string.isRequired,
  warningDialogDescription: PropTypes.string.isRequired,
};

export default FormMultiSelectionField;
