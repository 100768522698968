import React from 'react';
import '../../css/navigation/NavigationMenuItem.css';
import { ChevronDownIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

const NavigationMenuItem = ({
  name,
  menuVisible,
  setMenuVisible,
  enabled,
  buttonRef,
}) => {
  if (enabled === false) {
    return (
      <button type="button" className="navigation-menu-item">
        <span>{name}</span>
      </button>
    );
  }
  return (
    <button
      type="button"
      className="navigation-menu-item"
      ref={buttonRef}
      onMouseEnter={() => {
        setMenuVisible(true);
      }}
    >
      <span>{name}</span>
      <NavigationChevron rotated={menuVisible} />
    </button>
  );
};

const NavigationChevron = ({ rotated }) => (
  <ChevronDownIcon
    className={`transform ${
      rotated
        ? 'transition duration-400 rotate-180'
        : 'transition duration-400 rotate-0'
    } navigation-chevron group-hover:text-gray-800`}
  />
);

NavigationMenuItem.defaultProps = {
  enabled: true,
};

NavigationMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  menuVisible: PropTypes.bool.isRequired,
  setMenuVisible: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  buttonRef: PropTypes.object.isRequired,
};

NavigationChevron.propTypes = {
  rotated: PropTypes.bool.isRequired,
};

export default NavigationMenuItem;
