import PropTypes from 'prop-types';
import { validModulesWithScores } from '../../utils/scores';
import { moduleSort } from '../../utils/sort';
import ModuleOverview from '../modules/ModuleOverview';
import { NoScoresWarning } from '../shared/NoScoresWarning';

export const AssessmentRowExpansion = ({ assessment, patient }) => {
  const modules = validModulesWithScores(assessment.modules);

  if (modules.length === 0) {
    return (
      <tr key={`${assessment.id}-expanded`}>
        <td colSpan={7} className="h-20">
          <NoScoresWarning patient={patient} />
        </td>
      </tr>
    );
  }

  return (
    <tr key={`${assessment.id}-expanded`}>
      <td colSpan={7} className="h-56">
        <div className="module-overview-container">
          {modules.sort(moduleSort).map((m) => (
            <ModuleOverview
              key={m.id}
              assessment={assessment}
              module={m}
              patient={patient}
            />
          ))}
        </div>
      </td>
    </tr>
  );
};

AssessmentRowExpansion.propTypes = {
  assessment: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
};
