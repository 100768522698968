import { useQuery } from 'react-query';
import { Patients } from '../api/patients';
import { Assessments } from '../api/assessments';
import { patientSchema, assessmentListSchema } from '../schemas/schemas';
import validate from '../utils/validate';

const getPatient = async (organizationID, patientID) => {
  const { data } = await Patients.get(organizationID, patientID);
  return data;
};

const getPatientAssessments = async (organizationID, patientID) => {
  const { data } = await Assessments.list(organizationID, patientID);
  return data;
};

function getPatientAndAssessments(organizationID, patientID) {
  return Promise.all([
    getPatient(organizationID, patientID),
    getPatientAssessments(organizationID, patientID),
  ]).then((data) => {
    // Promise.all is guaranteed to return responses in the order we supplied
    // when defining the promise. Therefore, Patient is always index 0, AssessmentList is index 1.
    const patient = data[0];
    const assessments = data[1];

    // Validate both the patient and the assessments
    validate(patient, patientSchema, 'patient', false);
    validate(assessments, assessmentListSchema, 'assessments', false);

    return { patient, assessments: assessments.data };
  });
}

function usePatientAndAssessments(organizationID, patientID) {
  return useQuery(
    [organizationID, 'patient', patientID, 'assessmentList'],
    () => getPatientAndAssessments(organizationID, patientID)
  );
}

export default usePatientAndAssessments;
