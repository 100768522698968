import React from 'react';
import PropTypes from 'prop-types';
import { FlagIcon } from '@heroicons/react/outline';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceArea,
  ReferenceDot,
} from 'recharts';
import '../../css/modules/ModuleGraph.css';
import {
  moduleIsValid,
  round,
  scoreIsModeratelyImpaired,
  scoreIsSeverelyImpaired,
} from '../../utils/scores';

const FlagReferenceDot = ({ cx, cy, color }) => (
  <circle cx={cx} r="7" cy={cy} fill={color}>
    <FlagIcon />
  </circle>
);

FlagReferenceDot.defaultProps = {
  cx: 0,
  cy: 0,
};

FlagReferenceDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  color: PropTypes.string.isRequired,
};

const displayableTickDate = (date) =>
  date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
  });

const displayableTooltipDate = (date) =>
  date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

const generateGraphData = (assessments, moduleKey) => {
  const graphData = [];

  assessments.forEach((a) => {
    a.modules
      .filter((mk) => mk.key === moduleKey)
      .filter((mk) => moduleIsValid(mk))
      .filter((mk) => mk.canceled === false)
      .forEach((m) => {
        graphData.push({
          date: a.created_at,
          Percentile: round(m.percentile * 100.0),
          zscore: round(m.zscore),
        });
      });
  });

  return graphData;
};

const ModuleGraph = ({ assessments, moduleKey }) => {
  const data = generateGraphData(assessments, moduleKey);
  return (
    <div className="flex">
      <div className="graph-left">
        <p>Percentile</p>
      </div>
      <div className="graph-right">
        <h1>All Scores</h1>
        <LineChart width={320} height={280} data={data} className="graph">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(tickItem) =>
              displayableTickDate(new Date(tickItem))
            }
          />
          <YAxis />
          <Tooltip
            formatter={(value) => `${round(value)}%`}
            labelFormatter={(value) => displayableTooltipDate(new Date(value))}
          />

          <ReferenceArea
            x1={data[0].name}
            x2={data[data.length - 1].name}
            y1={2.28}
            y2={6.68} // 6.68 is the percentile equivalent of the -1.5 z-score, our yellow-flag cutoff
            fill="#FEF3C7"
            strokeOpacity={1.0}
          />
          <ReferenceArea
            x1={data[0].name}
            x2={data[data.length - 1].name}
            y1={0}
            y2={2.28} // 2.28 is the percentile equivalent of -2 z-score, our red-flag cutoff
            fill="#FCECEC"
            strokeOpacity={1.0}
          />

          <Line
            type="monotone"
            dataKey="Percentile"
            stroke="#00616E"
            strokeWidth="2px"
            activeDot={{ r: 3 }}
          />

          {/* Add yellow flags to graph data points */}
          {data
            .filter((d) => scoreIsModeratelyImpaired(d.zscore))
            .map((y) => (
              <ReferenceDot
                key={y.date}
                x={y.date}
                y={y.Percentile}
                shape={<FlagReferenceDot color="#D97708" />}
              />
            ))}

          {/* Add red flags to graph data points */}
          {data
            .filter((d) => scoreIsSeverelyImpaired(d.zscore))
            .map((r) => (
              <ReferenceDot
                key={r.date.toString()}
                x={r.date}
                y={r.Percentile}
                shape={<FlagReferenceDot color="#DC2626" />}
              />
            ))}
        </LineChart>
      </div>
    </div>
  );
};

ModuleGraph.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object).isRequired,
  moduleKey: PropTypes.string.isRequired,
};

export default ModuleGraph;
