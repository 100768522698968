import React from 'react';
import PropTypes from 'prop-types';
import '../../css/modules/ModuleDescription.css';
import {
  moduleImageForKey,
  moduleTitleForKey,
  moduleDescriptionForKey,
} from '../../utils/modules';

const ModuleDescription = ({ moduleKey }) => (
  <div className="module-description">
    {moduleImageForKey(moduleKey)}
    <h1>{moduleTitleForKey(moduleKey)}</h1>
    <p>{moduleDescriptionForKey(moduleKey)}</p>
  </div>
);

ModuleDescription.propTypes = {
  moduleKey: PropTypes.string.isRequired,
};

export default ModuleDescription;
