import React from 'react';
import PropTypes from 'prop-types';
import '../../css/modules/ModuleOverview.css';
import ModuleDescription from './ModuleDescription';
import {
  calculateSpecificScoreText,
  moduleIsValid,
  round,
} from '../../utils/scores';

const ModuleOverview = ({ patient, assessment, module }) => (
  <div className="module-overview">
    <div className="module-inner-section module-overview-row">
      <ModuleDescription moduleKey={module.key} />
    </div>
    <div className="module-inner-section">
      {/* Specific Score */}
      <div className="score">
        <div className="score-description">
          <div>{calculateSpecificScoreText(patient, assessment, module)}</div>
        </div>
        {moduleIsValid(module) && (
          <div className="score-values">
            <div className="score raw-score">
              <h1>Raw Score</h1>
              <h2>{module.raw_score && module.raw_score}</h2>
            </div>
            <div className="score z-score">
              <h1>Adjusted Z-Score</h1>
              {module.zscore && (
                <h2>
                  {module.zscore > 0 ? '+' : ''}
                  {round(module.zscore)}
                </h2>
              )}
            </div>
          </div>
        )}
      </div>
      {/* End Specific Score */}
    </div>
  </div>
);

ModuleOverview.propTypes = {
  patient: PropTypes.object.isRequired,
  assessment: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,
};

export default ModuleOverview;
