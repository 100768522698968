import { useQuery } from 'react-query';
import { Users } from '../api/users';

const useCheckConfirmation = (confirmationToken, user) =>
  useQuery(
    [confirmationToken],
    async () => {
      const { data } = await Users.checkConfirmation(confirmationToken);
      return data;
    },
    {
      enabled: confirmationToken !== undefined && user === undefined,
      refetchOnWindowFocus: false,
    }
  );

export default useCheckConfirmation;
