import { useMutation, useQueryClient } from 'react-query';
import { Devices } from '../api/devices';

const invalidateDeviceQuery = (queryClient, organizationID, deviceID) => {
  // Invalidate the device query so we re-fetch this device
  queryClient.invalidateQueries([organizationID, deviceID, 'devices', 'show']);
};

function useRegenerateDeviceKeys(organizationID, deviceID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation(() => Devices.regenerate(organizationID, deviceID), {
    onSuccess: async () => {
      invalidateDeviceQuery(queryClient, organizationID, deviceID);
      successCallback();
    },
  });
}

export default useRegenerateDeviceKeys;
