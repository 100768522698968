/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../shared/Form';
import DateTextField from '../shared/DateTextField';
import { useAuth } from '../../hooks/useAuth';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import { displayableDateFromDate } from '../../utils/format';
import environment from '../../config/environment';

const auditLog = yup.object().shape({
  start_date: yup.string().required(),
  end_date: yup
    .date()
    .default(null)
    .when(
      'start_date',
      (start_date, y) =>
        start_date && y.min(start_date, 'End date cannot be before start time')
    ),
});

const AddAuditLog = () => {
  const { organization } = useAuth();
  const setBannerMessage = useContext(InformationBannerContext);

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(auditLog),
  });

  return (
    <>
      <Form
        title="Generate Audit Log"
        description="Enter the start and end date for the audit log below."
        isPosting={false}
        isError={false}
        onSubmit={handleSubmit((data) => {
          const start = displayableDateFromDate(new Date(data.start_date));
          const end = displayableDateFromDate(new Date(data.end_date));
          const url = `${environment.apiURL}/v1/organizations/${organization.id}/audit-logs/download?from=${start}&to=${end}`;

          setBannerMessage('Audit log generated successfully.');
          window.scrollTo(0, 0);

          window.location.href = url;
        })}
        submitText="Generate Audit Log"
        submitIcon={<PlusIcon />}
        cancelLink="/"
        validationErrors={errors}
      >
        <DateTextField
          register={register}
          error={errors.start_date}
          title="Start Date"
          formKey="start_date"
          control={control}
        />
        <DateTextField
          register={register}
          error={errors.end_date}
          title="End Date"
          formKey="end_date"
          control={control}
        />
      </Form>
    </>
  );
};

export default AddAuditLog;
