import React from 'react';
import PropTypes from 'prop-types';
import '../../css/shared/TabView.css';

const TabView = ({ currentTab, setCurrentTab, tabs }) => (
  <ul className="tabview">
    {tabs.map((tab) => (
      <li key={tab.key}>
        <button
          type="button"
          className="focus:outline-none"
          onClick={() => {
            setCurrentTab(tab.key);
          }}
        >
          <div
            className={`${
              tab.key === currentTab
                ? 'tabview-button-inner-selected'
                : 'tabview-button-inner'
            }`}
          >
            <div
              className={`${
                tab.key === currentTab ? 'tab-icon-selected' : 'tab-icon'
              }`}
            >
              {tab.icon}
            </div>
            <div
              className={`${
                tab.key === currentTab ? 'tab-text-selected' : 'tab-text'
              }`}
            >
              {tab.name}
            </div>
          </div>
        </button>
      </li>
    ))}
  </ul>
);

TabView.propTypes = {
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
};

export default TabView;
