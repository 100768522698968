import { useQuery } from 'react-query';
import { Users } from '../api/users';

const useCheckResetPassword = (resetPasswordToken, user) =>
  useQuery(
    [resetPasswordToken],
    async () => {
      const { data } = await Users.checkResetPassword(resetPasswordToken);
      return data;
    },
    {
      enabled: resetPasswordToken !== undefined && user === undefined,
      refetchOnWindowFocus: false,
    }
  );

export default useCheckResetPassword;
