import { useQuery } from 'react-query';
import { Sessions } from '../api/sessions';
import { userSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function useSession(options) {
  return useQuery(
    ['session', 'get'],
    async () => {
      const { data } = await Sessions.get();
      await validate(data, userSchema, 'session', false);
      return data;
    },
    options
  );
}

export default useSession;
