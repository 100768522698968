import { useMutation, useQueryClient } from 'react-query';
import { Users } from '../api/users';

function useDisableUser(organizationID, userID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation(() => Users.disable(organizationID, userID), {
    onSuccess: async () => {
      queryClient.invalidateQueries([organizationID, 'user', userID]);
      successCallback();
    },
  });
}

export default useDisableUser;
