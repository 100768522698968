import { useMutation, useQueryClient } from 'react-query';
import { Users } from '../api/users';

function useEditUser(organizationID, userID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation((data) => Users.update(organizationID, userID, data), {
    onSuccess: async (response) => {
      queryClient.invalidateQueries();
      successCallback(response);
    },
  });
}

export default useEditUser;
