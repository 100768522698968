import React from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import Button from './Button';
import '../../css/shared/DetailView.css';

const DetailView = ({
  title,
  description,
  children,
  additionalButtons,
  backLink,
}) => {
  const history = useHistory();
  return (
    <div className="detail-view">
      <form className="detail-view-outer">
        <div className="detail-view-inner">
          <div className="detail-view-header">
            <div>
              <h3>{title}</h3>
              <p>{description}</p>
            </div>

            <div className="detail-view-children">{children}</div>
          </div>
        </div>

        <div className="detail-view-tray">
          <div className="detail-view-buttons">
            <Button
              name="Go Back"
              icon={<ArrowLeftIcon />}
              onClick={() => {
                history.push(backLink);
              }}
              isSecondaryStyle
            />
            <div className="ml-auto">{additionalButtons}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

DetailView.defaultProps = {
  additionalButtons: [],
};

DetailView.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  additionalButtons: PropTypes.arrayOf(PropTypes.element),
  backLink: PropTypes.string.isRequired,
};

export default DetailView;
