/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import useUnlockUser from '../../hooks/useUnlockUser';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import NoDataPageHeader from '../shared/NoDataPageHeader';
import Button from '../shared/Button';
import ErrorAlert from '../shared/ErrorAlert';

const queryString = require('query-string');

const UnlockUser = () => {
  const params = queryString.parse(location.search);
  const setBannerMessage = useContext(InformationBannerContext);

  const mutation = useUnlockUser((response) => {});

  useEffect(() => {
    mutation.mutate(params.lockedToken);
  }, []);

  return (
    <div className="mt-24">
      {mutation.isError && (
        <div className="app-container">
          <ErrorAlert title="Error" message={mutation.error.message} />
        </div>
      )}
      {!mutation.isError && (
        <NoDataPageHeader
          title={mutation.isLoading ? 'Unlocking User...' : 'User Unlocked'}
          refreshing={mutation.isLoading}
          description={
            <>
              {
                (mutation.isLoading && (
                  <div>
                    <p className="no-data-page-header-description">
                      Your account was locked because of too many failed login
                      attempts.
                    </p>
                    <p className="no-data-page-header-description">
                      Please wait while your account is unlocked.
                    </p>
                  </div>
                ),
                !mutation.isLoading && (
                  <div>
                    <p className="no-data-page-header-description">
                      Your account has been unlocked. Please login to continue.
                    </p>
                  </div>
                ))
              }
            </>
          }
          button={
            <Button
              name="Log In"
              icon={<ArrowLeftIcon />}
              className="mx-auto"
              link="/"
            />
          }
        />
      )}
    </div>
  );
};

export default UnlockUser;
