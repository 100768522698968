import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SortDescendingIcon } from '@heroicons/react/outline';
import '../../css/shared/Table.css';
import '../../css/patients/PatientTable.css';
import PatientRow from './PatientRow';
import { Assessments } from '../../api/assessments';
import { useAuth } from '../../hooks/useAuth';
import ResultsPDF from './ResultsPDF';

const PatientTable = ({ patients }) => {
  const { organization } = useAuth();
  const [assessments, setAssessments] = useState();
  const [pdfVisible, setPdfVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const pdfRef = React.createRef();

  const generatePdf = async (patient, toPdf) => {
    setAssessments(undefined); // clear any previous PDF results
    setPdfVisible(true);
    setSelectedPatient(patient);

    const { data } = await Assessments.list(organization.id, patient.id);
    setAssessments(data.data);

    // We need to wait for the PDF component to render
    // before generating the file.
    //
    // We may be able to reduce this time by using useEffect()
    setTimeout(() => {
      toPdf();
    }, 1800);
  };

  return (
    <div className="table">
      <div className="table-inner">
        <div className="table-wrapper">
          <div className="table-wrapper-shadow">
            <table>
              <thead>
                <tr>
                  <th className="flex" scope="col">
                    Last Assessment <SortDescendingIcon className="sort-icon" />
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">DOB</th>
                  <th scope="col">MRN</th>
                  <th className="table-th-buttons" scope="col">
                    Download
                  </th>
                  <th className="table-th-buttons" scope="col">
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient) => (
                  <PatientRow
                    key={patient.id}
                    patient={patient}
                    generatePdf={generatePdf}
                    pdfVisible={pdfVisible}
                    setPdfVisible={setPdfVisible}
                    pdfRef={pdfRef}
                    selectedPatient={selectedPatient}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pdfVisible && selectedPatient && assessments && (
        <div className="pdf" ref={pdfRef}>
          <ResultsPDF patient={selectedPatient} assessments={assessments} />
        </div>
      )}
    </div>
  );
};

PatientTable.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PatientTable;
