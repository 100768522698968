import api from '../config/api';
import { generateQueryString } from '../hooks/usePagination';

export const Requests = {
  list: (organizationID, page) =>
    api.get(
      `organizations/${organizationID}/requests?${generateQueryString(
        page,
        'created_at',
        'desc'
      )}`
    ),
};

export default Requests;
