import { useMutation, useQueryClient } from 'react-query';
import { Users } from '../api/users';

function useAddUser(organizationID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation((data) => Users.create(organizationID, data), {
    onSuccess: async (response) => {
      // Invalidate the user query so we re-fetch a new list of users
      queryClient.invalidateQueries([organizationID, 'users', 'list']);
      successCallback(response);
    },
  });
}

export default useAddUser;
