import { PlusIcon } from '@heroicons/react/outline';
import React, { useContext, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Button from '../shared/Button';
import '../../css/shared/NoDataPageHeader.css';
import '../../css/shared/FormTextField.css';
import environment from '../../config/environment';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';

const CreateResearchCSV = () => {
  const { organization } = useAuth();
  const [deidentifyData, setDeidentifyData] = useState(false);
  const setBannerMessage = useContext(InformationBannerContext);

  return (
    <div className="no-data-page-header">
      <p className="no-data-page-header-subtitle">{organization.name}</p>
      <h1 className="no-data-page-header-title">Research CSV</h1>
      <p className="no-data-page-header-description">
        Generate a CSV for this organization by clicking the button below.
      </p>
      <div className="mt-20">
        <input
          id="deidentify"
          className="form-checkbox mb-3 mr-2"
          type="checkbox"
          checked={deidentifyData}
          onChange={(e) => {
            setDeidentifyData(e.target.checked);
          }}
        />
        <label htmlFor="deidentify">De-identify Data</label>
        <br />
        <br />
        <Button
          name="Generate CSV File"
          icon={<PlusIcon />}
          className="mx-auto mt-6"
          onClick={() => {
            setBannerMessage('Research CSV file generated successfully.');
            window.scrollTo(0, 0);
            window.location.href = `${environment.apiURL}/v1/organizations/${organization.id}/csvs/download?deid=${deidentifyData}`;
          }}
        />
      </div>
    </div>
  );
};

export default CreateResearchCSV;
