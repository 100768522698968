import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import '../../css/shared/DetailItem.css';
import { displayableDateTime } from '../../utils/format';

const DetailDateItem = ({ title, value }) => (
  <div className="detail-item-textfield-grid">
    <label htmlFor={title} className="detail-item-label">
      {title}
    </label>
    <div className="detail-item-input">
      <p className="detail-item-value">
        {displayableDateTime(value)} (<TimeAgo date={value} />)
      </p>
    </div>
  </div>
);

DetailDateItem.defaultProps = {
  value: '',
};

DetailDateItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default DetailDateItem;
