import React from 'react';
import LoadingPatientDetailImage from '../../images/patient-detail-loading.jpg';

const LoadingPatientDetail = () => (
  <div className="mt-14 w-full m-auto">
    <img
      className="animate-pulse"
      src={LoadingPatientDetailImage}
      alt="Loading Patient Data"
    />
  </div>
);

export default LoadingPatientDetail;
