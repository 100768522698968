import React from 'react';
import PropTypes from 'prop-types';
import '../../css/shared/DetailItem.css';

const DetailItem = ({ title, value, link, dataTestID, additionalButtons }) => (
  <div className="detail-item-textfield-grid">
    <label htmlFor={title} className="detail-item-label">
      {title}
    </label>
    <div className="detail-item-input">
      {link && (
        <p data-testid={dataTestID} className="detail-item-value">
          <a href={link} className="detail-item-link">
            {value}
          </a>
        </p>
      )}

      {link === undefined && <p data-testid={dataTestID}>{value}</p>}

      {<p>{additionalButtons}</p>}
    </div>
  </div>
);

DetailItem.defaultProps = {
  value: '',
  link: undefined,
  dataTestID: undefined,
  additionalButtons: [],
};

DetailItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  link: PropTypes.string,
  dataTestID: PropTypes.string,
  additionalButtons: PropTypes.arrayOf(PropTypes.element),
};

export default DetailItem;
