export default function generateRoles(currentRole) {
  const roles = [
    { value: 'user', title: 'HCP' },
    { value: 'admin', title: 'Admin' },
  ];
  if (currentRole === 'super_admin') {
    roles.push({ value: 'super_admin', title: 'Super Admin' });
  }
  return roles;
}
