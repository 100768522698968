import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/navigation/SuperAdminMenuItem.css';
import PropTypes from 'prop-types';

const UserMenuItem = ({ icon, name, description, link, dismiss }) => {
  const history = useHistory();

  const styledIcon = React.cloneElement(icon, {
    className: 'super-admin-menu-item-icon',
  });

  const handleClick = () => {
    history.push(link);
    dismiss();
  };

  return (
    <li className="super-admin-menu-item">
      <button type="button" className="super-admin-link" onClick={handleClick}>
        <div className="super-admin-content">
          {styledIcon}{' '}
          <p className="super-admin-p">
            {name}{' '}
            <span className="super-admin-p-description">{description}</span>
          </p>
        </div>
      </button>
    </li>
  );
};

UserMenuItem.propTypes = {
  icon: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};

export default UserMenuItem;
