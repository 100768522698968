import { useQuery } from 'react-query';
import { Devices } from '../api/devices';
import { devicesSchema } from '../schemas/schemas';
import validate from '../utils/validate';
import { useAuth } from './useAuth';

function useDevices(page) {
  const { organization } = useAuth();
  return useQuery(
    [organization.id, 'devices', 'list', page],
    async () => {
      const { data } = await Devices.list(organization.id, page);
      await validate(data, devicesSchema, 'devices', false);
      return data;
    },
    {
      keepPreviousData: true,
    }
  );
}

export default useDevices;
