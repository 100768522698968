import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../css/shared/DetailItem.css';
import { userCanViewOrganization } from '../../utils/privileges';
import { useAuth } from '../../hooks/useAuth';

const OrganizationItem = ({ organization }) => {
  const { user } = useAuth();
  if (userCanViewOrganization(user)) {
    return (
      <Link to={`/organizations/${organization.id}`}>{organization.name}</Link>
    );
  }
  return <span>{organization.name}</span>;
};

const DetailItemOrganizationList = ({ title, organizations }) => (
  <div className="detail-item-textfield-grid">
    <label htmlFor={title} className="detail-item-label">
      {title}
    </label>
    <div className="detail-item-input">
      <ul>
        {organizations.map((org) => (
          <li key={org.id}>
            <OrganizationItem organization={org} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

DetailItemOrganizationList.defaultProps = {
  organizations: [],
};

DetailItemOrganizationList.propTypes = {
  title: PropTypes.string.isRequired,
  organizations: PropTypes.array,
};

OrganizationItem.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default DetailItemOrganizationList;
