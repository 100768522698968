import React, { useContext, createContext, useState } from 'react';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { Sessions } from '../api/sessions';
import { Users } from '../api/users';
import useSession from './useSession';
import validate from '../utils/validate';
import { userSchema } from '../schemas/schemas';

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

export const useAuth = () => useContext(authContext);

const getCurrentOrganizationForUser = (user) =>
  user.organizations.find((org) => org.id === user.current_organization_id);

const useProvideAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const [organization, setOrganization] = useState(undefined);
  const [didTriggerIdleTimer, setDidTriggerIdleTimer] = useState(false);

  let loginSuccessCallback = () => {};
  let loginFailureCallback = () => {};

  let confirmSuccessCallback = () => {};
  let confirmFailureCallback = () => {};

  let resetPasswordSuccessCallback = () => {};
  let resetPasswordFailureCallback = () => {};

  let recoverAccountSuccessCallback = () => {};
  let recoverAccountFailureCallback = () => {};

  const createLocalSession = (response) => {
    if (response) {
      setOrganization(getCurrentOrganizationForUser(response));
      setUser(response);
    }
  };

  const deleteLocalSession = () => {
    setOrganization(undefined);
    setUser(undefined);
  };

  // Create Session
  const sendCreateSessionRequest = ({ email, password }) =>
    Sessions.create(email, password);
  const createSessionMutation = useMutation(sendCreateSessionRequest, {
    onSuccess: (response) => {
      setDidTriggerIdleTimer(false);
      createLocalSession(response.data);
      loginSuccessCallback(response.data);
    },
    onError: (error) => {
      loginFailureCallback(error.response?.data);
    },
  });

  // Delete Session
  const sendDeleteSessionRequest = () => Sessions.delete();
  const deleteSessionMutation = useMutation(sendDeleteSessionRequest, {
    onSuccess: () => {
      deleteLocalSession();
    },
  });

  // Get Session
  useSession({
    onSuccess: (response) => {
      createLocalSession(response);
      setIsLoading(false);
    },
    onError: () => {
      deleteLocalSession();
      setIsLoading(false);
    },
  });

  // Confirm Account
  const sendConfirmUserRequest = ({
    userID,
    password,
    passwordConfirmation,
    confirmationToken,
  }) =>
    Users.confirm(userID, password, passwordConfirmation, confirmationToken);
  const confirmUserMutation = useMutation(sendConfirmUserRequest, {
    onSuccess: (response) => {
      createLocalSession(response.data);
      confirmSuccessCallback(response);
    },
    onError: (error) => {
      confirmFailureCallback(error);
    },
  });

  // Reset Password
  const resetPasswordRequest = ({
    userID,
    newPassword,
    passwordConfirmation,
    resetPasswordToken,
  }) =>
    Users.resetPassword(
      userID,
      newPassword,
      passwordConfirmation,
      resetPasswordToken
    );
  const resetPasswordMutation = useMutation(resetPasswordRequest, {
    onSuccess: async (response) => {
      createLocalSession(response.data);

      // Since the ResetPassword response doesn't give us the user info,
      // we need to request the current session to log the user in.
      const { data } = await Sessions.get();
      await validate(data, userSchema, 'session', false);
      createLocalSession(data);

      // Call the callback after the current session has been established
      resetPasswordSuccessCallback();
    },
    onError: (error) => {
      resetPasswordFailureCallback(error);
    },
  });

  const resetPassword = (
    userID,
    newPassword,
    passwordConfirmation,
    resetPasswordToken,
    lconfirmSuccessCallback,
    lconfirmFailureCallback
  ) => {
    resetPasswordSuccessCallback = lconfirmSuccessCallback;
    resetPasswordFailureCallback = lconfirmFailureCallback;
    resetPasswordMutation.mutate({
      userID,
      newPassword,
      passwordConfirmation,
      resetPasswordToken,
      resetPasswordSuccessCallback,
      resetPasswordFailureCallback,
    });
  };

  // Recover Account

  const sendRecoverRequest = (email) => Users.recoverUser(email);
  const sendRecoveryMutation = useMutation(sendRecoverRequest, {
    onSuccess: () => {
      recoverAccountSuccessCallback();
    },
    onError: (error) => {
      recoverAccountFailureCallback(error);
    },
  });

  const signin = (email, password, sbc, fcb) => {
    loginSuccessCallback = sbc;
    loginFailureCallback = fcb;
    createSessionMutation.mutate({
      email,
      password,
      loginSuccessCallback,
      loginFailureCallback,
    });
  };

  const signout = (signoutCallback) => {
    deleteSessionMutation.mutate();
    signoutCallback();
  };

  const clearUser = () => {
    setUser(undefined);
    setOrganization(undefined);
  };

  const updateUser = (newUser) => {
    setOrganization(getCurrentOrganizationForUser(newUser));
    setUser(newUser);
  };

  const confirmUser = (
    userID,
    password,
    passwordConfirmation,
    confirmationToken,
    lconfirmSuccessCallback,
    lconfirmFailureCallback
  ) => {
    confirmSuccessCallback = lconfirmSuccessCallback;
    confirmFailureCallback = lconfirmFailureCallback;
    confirmUserMutation.mutate({
      userID,
      password,
      passwordConfirmation,
      confirmationToken,
      confirmSuccessCallback,
      confirmFailureCallback,
    });
  };

  const recoverUser = (
    email,
    lrecoverAccountSuccessCallback,
    lrecoverAccountFailureCallback
  ) => {
    recoverAccountSuccessCallback = lrecoverAccountSuccessCallback;
    recoverAccountFailureCallback = lrecoverAccountFailureCallback;
    sendRecoveryMutation.mutate(email);
  };

  return {
    user,
    updateUser,
    organization,
    createSessionMutation,
    deleteSessionMutation,
    confirmUserMutation,
    sendRecoveryMutation,
    signin,
    signout,
    recoverUser,
    confirmUser,
    resetPassword,
    didTriggerIdleTimer,
    setDidTriggerIdleTimer,
    clearUser,
    isLoading,
  };
};

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

ProvideAuth.propTypes = {
  children: PropTypes.element.isRequired,
};
