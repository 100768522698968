import api from '../config/api';

export const Sessions = {
  create: (email, password) =>
    api.post(
      `sessions`,
      {},
      {
        auth: {
          username: email,
          password,
        },
      }
    ),
  get: () => api.get(`sessions`),
  delete: () => api.delete('sessions'),
};

export default Sessions;
