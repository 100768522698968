import { useMutation, useQueryClient } from 'react-query';
import { Devices } from '../api/devices';

function useAddDevice(organizationID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation((data) => Devices.create(organizationID, data), {
    onSuccess: async (response) => {
      // Invalidate the device query so we re-fetch a new list of devices
      queryClient.invalidateQueries([organizationID, 'devices', 'list']);
      successCallback(response);
    },
  });
}

export default useAddDevice;
