import React from 'react';
import useUsers from '../../hooks/useUsers';
import UserTable from './UserTable';
import ListPage from '../shared/ListPage';
import { usePagination } from '../../hooks/usePagination';
import { useAuth } from '../../hooks/useAuth';

const UserList = () => {
  const { page, setPage } = usePagination();
  const { organization } = useAuth();
  const query = useUsers(organization.id, page);

  return (
    <ListPage
      title="Users"
      description="The users for this organization are listed below."
      noObjectsTitle="No Users Found"
      noObjectsDescription="There are no users in this organization. To add a user, click 'New User' below."
      newObjectButtonTitle="New User"
      newObjectLink="/users/new"
      query={query}
      page={page}
      setPage={setPage}
      table={(data) => <UserTable users={data} />}
      hideOrganizationName
    />
  );
};

export default UserList;
