import React, { useContext } from 'react';
import { SaveIcon } from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuth } from '../../hooks/useAuth';
import Form from '../shared/Form';
import FormTextField from '../shared/FormTextField';
import LoadingDetail from '../shared/LoadingDetail';
import useEditPatient from '../../hooks/useEditPatient';
import usePatient from '../../hooks/usePatient';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import DisplayError from '../shared/DisplayError';

const editPatientSchema = yup.object().shape({
  first_name: yup.string().trim().required(),
  last_name: yup.string().trim().required(),
});

const EditPatient = () => {
  const { organization } = useAuth();
  const { patientID } = useParams();
  const history = useHistory();
  const setBannerMessage = useContext(InformationBannerContext);

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editPatientSchema),
  });

  // API handler
  const mutation = useEditPatient(organization.id, patientID, (response) => {
    setBannerMessage('Updated patient successfully.');
    history.push(`/patients/${response.data.id}`);
  });

  const {
    data: patient,
    isLoading,
    isError,
    error,
  } = usePatient(organization.id, patientID);

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  const onSubmit = (data) => {
    // We need to send all of the patient fields to the API,
    // but we don't want to send all of the calculated fields,
    // like 'flagged', etc.
    const fullPatientData = data;
    fullPatientData.races = patient.races;
    fullPatientData.education = patient.education;
    fullPatientData.date_of_birth = patient.date_of_birth;
    fullPatientData.mrn = patient.mrn;
    fullPatientData.sex = patient.sex;
    mutation.mutate(fullPatientData);
  };

  return (
    <>
      <Form
        title="Edit Patient"
        description="Update details about the given patient."
        onSubmit={handleSubmit(onSubmit)}
        submitText="Update Patient"
        submitIcon={<SaveIcon />}
        cancelLink={`/patients/${patientID}`}
        isPosting={mutation.isLoading}
        isError={mutation.isError}
        error={mutation.error}
        validationErrors={errors}
      >
        <FormTextField
          register={register}
          error={errors.first_name}
          title="First Name"
          formKey="first_name"
          defaultValue={patient.first_name}
          dataTestID="first_name"
        />
        <FormTextField
          register={register}
          error={errors.last_name}
          title="Last Name"
          formKey="last_name"
          defaultValue={patient.last_name}
          dataTestID="last_name"
        />
      </Form>
    </>
  );
};

export default EditPatient;
