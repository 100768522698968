import React from 'react';
import PropTypes from 'prop-types';
import SkeletonTable from './SkeletonTable';

const SearchHeader = ({
  searchResults,
  searchTerm,
  resetSearch,
  isLoadingSearch,
  isError,
  error,
}) => {
  if (isError) {
    return <p>Error: {error.message}</p>;
  }
  if (isLoadingSearch) {
    return <SkeletonTable />;
  }
  if (!searchResults) {
    return <p>No search results found.</p>;
  }
  return (
    <div className="mb-5 font-medium">
      <p>
        Showing {searchResults.length} result
        {searchResults.length === 1 ? '' : 's'} for &apos;{searchTerm}
        &apos;
        <button
          className="text-qr8-green font-bold underline ml-2"
          type="button"
          onClick={resetSearch}
        >
          Clear Search
        </button>
      </p>
    </div>
  );
};

SearchHeader.defaultProps = {
  searchResults: [],
  error: undefined,
};

SearchHeader.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.object),
  searchTerm: PropTypes.string.isRequired,
  resetSearch: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoadingSearch: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default SearchHeader;
