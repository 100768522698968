import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RefreshIcon, XCircleIcon } from '@heroicons/react/solid';
import DetailItem from '../shared/DetailItem';
import QrCodeItem from '../shared/QrCodeItem';
import DetailView from '../shared/DetailView';
import useDevice from '../../hooks/useDevice';
import LoadingDetail from '../shared/LoadingDetail';
import Button from '../shared/Button';
import { useAuth } from '../../hooks/useAuth';
import DetailDateItem from '../shared/DetailDateItem';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import useDeactivateDevice from '../../hooks/useDeactivateDevice';
import useRegenerateDeviceKeys from '../../hooks/useRegenerateDeviceKeys';
import { displayableDeviceType } from '../../utils/format';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import DisplayError from '../shared/DisplayError';

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const dateInFuture = (date) => date > new Date();
const pollInterval = 3000; // Poll interval in milliseconds

const ShowDevice = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { organization } = useAuth();
  const { deviceID } = useParams();
  const setBannerMessage = useContext(InformationBannerContext);
  const [refetchInterval, setRefreshInterval] = useState(false);
  const {
    data: device,
    isLoading,
    isError,
    error,
  } = useDevice(organization.id, deviceID, {
    refetchInterval,
    onSuccess: (response) => {
      if (
        response &&
        response.active &&
        !response.confirmed &&
        dateInFuture(new Date(response.qr_code_expiration))
      ) {
        setRefreshInterval(pollInterval);
      } else {
        setRefreshInterval(false);
      }
    },
  });

  const deactivate = useDeactivateDevice(organization.id, deviceID, () => {
    // On successfully deactivating a device, scroll up and show an alert
    setBannerMessage('This device was deactivated.');
    window.scrollTo(0, 0);
  });

  const regenerate = useRegenerateDeviceKeys(organization.id, deviceID, () => {
    // On successfully regenerating a device, scroll up and show an alert
    setBannerMessage('The QR code for this device was regenerated.');
    window.scrollTo(0, 0);
  });

  const shouldShowBarcode = () => device && device.active && !device.confirmed;

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  const additionalButtons = [];
  if (device.active !== false) {
    additionalButtons.push(
      <Button
        key="deactivate"
        name="Deactivate"
        icon={<XCircleIcon />}
        isLoading={deactivate.isLoading}
        onClick={() => {
          setBannerMessage(undefined);
          setShowDialog(true);
        }}
        isSecondaryStyle
      />
    );
  }

  if (shouldShowBarcode()) {
    additionalButtons.push(
      <Button
        key="regenerate"
        name="Regenerate"
        isLoading={regenerate.isLoading}
        icon={<RefreshIcon />}
        onClick={() => {
          setBannerMessage(undefined);
          regenerate.mutate();
        }}
      />
    );
  }

  return (
    <>
      <ConfirmationDialog
        title="Deactivate Device"
        message="Are you sure you would like to deactivate this device? The device will no longer be usable. This action cannot be undone."
        open={showDialog}
        setOpen={setShowDialog}
        destructiveButtonTitle="Deactivate"
        destructiveFunction={() => {
          deactivate.mutate();
          setShowDialog(false);
        }}
      />

      <DetailView
        title={device.name}
        description="Device Details"
        additionalButtons={additionalButtons}
        backLink="/devices"
      >
        <>
          {shouldShowBarcode() && (
            <QrCodeItem
              device={device}
              isLoading={regenerate.isLoading}
              regenerate={() => {
                setBannerMessage(undefined);
                regenerate.mutate();
              }}
            />
          )}
          <DetailItem title="Device Name" value={device.name} />
          <DetailItem title="Serial Number" value={device.serial_number} />
          <DetailItem
            title="Active"
            value={capitalize(device.active.toString())}
          />
          <DetailItem
            title="Confirmed"
            value={capitalize(device.confirmed.toString())}
          />
          {device.confirmed && (
            <DetailDateItem title="Last Used" value={device.last_used} />
          )}

          <DetailItem
            title="Device Type"
            value={displayableDeviceType(device.type)}
          />
          {device.confirmed && (
            <DetailItem title="App Version" value={device.app_version} />
          )}
          {device.confirmed && (
            <DetailItem title="OS Version" value={device.os_version} />
          )}
        </>
      </DetailView>
    </>
  );
};

export default ShowDevice;
