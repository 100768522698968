import { useQuery } from 'react-query';
import { Organizations } from '../api/organizations';
import { organizationListSchema } from '../schemas/schemas';
import validate from '../utils/validate';

export function useOrganizations(page) {
  return useQuery(['organizations', 'list', page], async () => {
    const { data } = await Organizations.list(page);
    await validate(data, organizationListSchema, 'organizations', false);
    return data;
  });
}

export function useAllOrganizations(currentUser) {
  return useQuery(
    ['organizations', 'list'],
    async () => {
      const { data } = await Organizations.listAll();
      await validate(data, organizationListSchema, 'organizations', false);
      return data;
    },
    // If the user is an admin, we don't want to perform
    // this query, as the list of organizations comes from their User object
    { enabled: currentUser.role === 'super_admin' }
  );
}

export default useOrganizations;
