import React from 'react';
import PropTypes from 'prop-types';
import '../../css/navigation/MenuItem.css';
import { useHistory } from 'react-router-dom';

const MenuItem = ({ icon, title, description, link, onClick, dismiss }) => {
  const history = useHistory();

  // Add the menu-image className to the icon prop so it's styled properly
  const styledIcon = React.cloneElement(icon, {
    className: 'menu-image',
  });

  const handleClick = () => {
    if (link) {
      history.push(link);
    }
    if (onClick) {
      onClick();
    }
    dismiss();
  };

  return (
    <button type="button" className="menu-link" onClick={handleClick}>
      <div className="menu-wrapper">
        {styledIcon}
        <div className="menu-item">
          <p className="menu-item-title">{title}</p>
          <p className="menu-item-description">{description}</p>
        </div>
      </div>
    </button>
  );
};

MenuItem.defaultProps = {
  link: undefined,
  onClick: () => {},
};

MenuItem.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default MenuItem;
