import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { LoginIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../hooks/useAuth';
import qr8Logo from '../../images/qr8logo.png';
import '../../css/authentication/Login.css';
import '../../css/shared/FormTextField.css';
import Alert from '../shared/Alert';
import Button from '../shared/Button';
import LoginErrorAlert from '../shared/LoginErrorAlert';
import UserAccountBackground from '../users/UserAccountBackground';
import { loginErrors } from '../../utils/response';

const userLogin = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const useQuery = () => new URLSearchParams(useLocation().search);

const Login = () => {
  const auth = useAuth();
  const query = useQuery();
  const [emailInput, setEmailInput] = useState(query.get('email'));
  const [error, setError] = useState();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userLogin),
  });

  const onSubmit = ({ email, password }) => {
    auth.signin(
      email,
      password,
      () => {
        // Success
        // We don't need to do anything here, as the page is re-rendered with a valid session
      },
      (errorResponse) => {
        // Error
        if (errorResponse.status?.code === loginErrors.expiredPassword) {
          history.push(
            `/reset-password?resetPasswordToken=${errorResponse.status?.reset_password_token}&expired=true`
          );
        } else {
          setError(errorResponse);
        }
      }
    );
  };

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  return (
    <div className="login-wrapper">
      <div className="login-inner-wrapper">
        <div className="login-left">
          <div>
            <img className="login-image" src={qr8Logo} alt="Qr8 Health" />
            <h2 className="login-slogan">
              Pioneering patient assessment tools for the measurement of
              neurological and motor function.
            </h2>
          </div>

          {error && <LoginErrorAlert email={emailInput} />}

          {query.get('message') === 'recovery' && error === undefined && (
            <div className="-mt-4 mb-16">
              <Alert message="Recovery email sent. Please check your inbox and spam folders." />
            </div>
          )}

          {auth.didTriggerIdleTimer && !error && (
            <div className="-mt-4 mb-16">
              <Alert message="You have been logged out due to inactivity. Please log in to continue." />
            </div>
          )}

          {/* If we're on mobile, present an alert about lack of support */}
          {(window.screen.width < 768 ||
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )) && (
            <div className="-mt-4 mb-16">
              <Alert message="This website does not support mobile devices. Please use a desktop browser for best results." />
            </div>
          )}

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className={
                      errors.email ? 'login-label-error' : 'login-label'
                    }
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      data-testid="SignInTextField"
                      defaultValue={emailInput}
                      onInput={handleEmailChange}
                      onBlur={handleEmailChange}
                      required
                      disabled={auth.createSessionMutation.isLoading}
                      className={
                        errors.email
                          ? 'login-textfield-error'
                          : 'login-textfield'
                      }
                      {...register('email')}
                    />
                    <div className="login-error">
                      {errors.email && <span>Please enter a valid email.</span>}
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className={
                      errors.password ? 'login-label-error' : 'login-label'
                    }
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      data-testid="PasswordTextField"
                      autoComplete="current-password"
                      disabled={auth.createSessionMutation.isLoading}
                      required
                      className={
                        errors.password
                          ? 'login-textfield-error'
                          : 'login-textfield'
                      }
                      {...register('password')}
                    />
                    <div className="login-error">
                      {errors.password && <span>Please enter a password.</span>}
                    </div>
                  </div>
                </div>

                <div>
                  <Button
                    name={
                      auth.createSessionMutation.isLoading
                        ? 'Please wait...'
                        : 'Sign in'
                    }
                    type="submit"
                    additionalStyle="login-button"
                    icon={<LoginIcon />}
                    onClick={handleSubmit(onSubmit)}
                    isLoading={auth.createSessionMutation.isLoading}
                    disabled={auth.createSessionMutation.isLoading}
                  />
                </div>

                <div className="text-sm text-center">
                  <Link
                    to={
                      emailInput
                        ? `/account-recovery?email=${emailInput}`
                        : '/account-recovery'
                    }
                    className="login-recovery"
                  >
                    Trouble signing in?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="login-right">
        <UserAccountBackground />
      </div>
    </div>
  );
};

export default Login;
