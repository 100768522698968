import React, { useState } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SortDescendingIcon,
} from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import '../../css/shared/Table.css';
import '../../css/assessments/AssessmentList.css';
import { useAuth } from '../../hooks/useAuth';
import AssessmentRow from './AssessmentRow';
import Button from '../shared/Button';
import { compareAssessments } from '../../utils/scores';
import { userCanDownloadAssessments } from '../../utils/privileges';

const generateExpansionTracker = (assessments) => {
  const expanded = {};
  assessments.forEach((a) => {
    expanded[a.id] = false;
  });
  return expanded;
};

const AssessmentList = ({ patient, assessments }) => {
  const { user, organization } = useAuth();
  const [expandedAssessments, setExpandedAssessments] = useState(
    generateExpansionTracker(assessments)
  );
  // Toggle expand true/false to re-render component when a specific row changes
  const [expand, setExpand] = useState(false);

  const expandAllAssessments = () => {
    const expanded = expandedAssessments;
    assessments.forEach((a) => {
      expanded[a.id] = true;
    });
    setExpandedAssessments(expanded);
    setExpand(!expand);
  };

  const collapseAllAssessments = () => {
    const expanded = expandedAssessments;
    assessments.forEach((a) => {
      expanded[a.id] = false;
    });
    setExpandedAssessments(expanded);
    setExpand(!expand);
  };

  return (
    <div className="assessment-list-container">
      <div className="assessment-list-button-tray">
        <Button
          name="Collapse All"
          icon={<ChevronUpIcon />}
          onClick={collapseAllAssessments}
        />
        <Button
          name="Expand All"
          icon={<ChevronDownIcon />}
          onClick={expandAllAssessments}
        />
      </div>
      <div className="assessment-list table">
        <div className="table-inner">
          <div className="table-wrapper">
            <div className="table-wrapper-shadow">
              <table>
                <thead>
                  <tr>
                    <th scope="col">Tests</th>
                    <th className="flex" scope="col">
                      Date <SortDescendingIcon className="sort-icon" />
                    </th>
                    <th scope="col">Assessor</th>
                    {organization.emr_enabled && (
                      <th scope="col">EMR Status</th>
                    )}
                    <th scope="col">Flagged</th>
                    {userCanDownloadAssessments(user) && (
                      <th className="table-th-buttons" scope="col">
                        Download
                      </th>
                    )}
                    <th className="table-th-buttons" scope="col">
                      Expand
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...assessments]
                    .sort(compareAssessments)
                    .reverse()
                    .map((assessment) => (
                      <AssessmentRow
                        key={assessment.id}
                        assessment={assessment}
                        organization={organization}
                        expandedAssessments={expandedAssessments}
                        setExpandedAssessments={setExpandedAssessments}
                        expand={expand}
                        setExpand={setExpand}
                        patient={patient}
                        user={user}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AssessmentList.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object).isRequired,
  patient: PropTypes.object.isRequired,
};

export default AssessmentList;
