import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import '../../css/shared/AppLogo.css';

const appName = 'Cognition Chronicle';

const AppLogo = () => (
  <Link to="/">
    <img width="307" height="30" className="logo" src={logo} alt={appName} />
  </Link>
);

export default AppLogo;
