import { useMutation, useQueryClient } from 'react-query';
import { Devices } from '../api/devices';

function useDeactivateDevice(organizationID, deviceID, successCallback) {
  const queryClient = useQueryClient();
  return useMutation(() => Devices.deactivate(organizationID, deviceID), {
    onSuccess: async () => {
      queryClient.invalidateQueries([
        organizationID,
        deviceID,
        'devices',
        'show',
      ]);
      successCallback();
    },
  });
}

export default useDeactivateDevice;
