import React from 'react';
import ProgressSpinner from './ProgressSpinner';

const Loading = () => (
  <div className="mx-auto w-20 mt-32">
    <ProgressSpinner styleClass="ml-5 mt-5" refreshing />
  </div>
);

export default Loading;
