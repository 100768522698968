import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/useAuth';
import ErrorAlert from './ErrorAlert';
import MissingResource from './MissingResource';
import { Sessions } from '../../api/sessions';

const DisplayError = ({ error }) => {
  const auth = useAuth();

  useEffect(async () => {
    if (error.message.includes('401')) {
      // If the user's session is still valid, we should
      // present the error below.

      // If it is not valid, we should present the login screen
      // with an explanation of why they were logged out.
      await Sessions.get().catch(() => {
        auth.setDidTriggerIdleTimer(true);
        auth.clearUser();
      });
    }
  });

  if (error.message.includes('404')) {
    return <MissingResource />;
  }

  return <ErrorAlert message={error.message} />;
};

DisplayError.propTypes = {
  error: PropTypes.object.isRequired,
};

export default DisplayError;
