import React from 'react';
import Pdf from 'react-to-pdf';
import TimeAgo from 'react-timeago';
import { DownloadIcon, EyeIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import Button from '../shared/Button';
import '../../css/patients/PatientRow.css';
import {
  displayableDate,
  displayableDateTime,
  truncate,
} from '../../utils/format';
import Tooltip from '../shared/Tooltip';
import { generatePdfFilename } from '../../utils/pdf';

const PatientRow = ({
  patient,
  generatePdf,
  pdfVisible,
  setPdfVisible,
  pdfRef,
  selectedPatient,
}) => (
  <tr>
    {patient.last_assessment_date === undefined && (
      <td className="patient-row-data">Never</td>
    )}
    {patient.last_assessment_date && (
      <td data-tip data-for={`${patient.id}-last-assessment`}>
        <div className="patient-table-data">
          <div className="patient-row-upper">
            {displayableDate(patient.last_assessment_date)}

            <div className="patient-row-lower">
              <TimeAgo date={patient.last_assessment_date} />
            </div>
          </div>
        </div>
        <Tooltip id={`${patient.id}-last-assessment`}>
          <span>{displayableDateTime(patient.last_assessment_date)}</span>
        </Tooltip>
      </td>
    )}
    <td className="patient-row-name">
      {truncate(`${patient.last_name}, ${patient.first_name}`, 25)}
    </td>
    <td className="patient-row-data">{patient.date_of_birth}</td>
    <td className="patient-row-data">{patient.mrn}</td>
    <td className="patient-row-data text-center">
      <Pdf
        targetRef={pdfRef}
        filename={generatePdfFilename()}
        scale={0.8}
        x={10}
        y={10}
        onComplete={() => {
          setPdfVisible(false);
        }}
      >
        {({ toPdf }) => (
          <Button
            dataTestID={`download-pdf-${patient.id}`}
            disabled={pdfVisible}
            icon={<DownloadIcon />}
            isLoading={selectedPatient === patient && pdfVisible}
            onClick={() => {
              generatePdf(patient, toPdf);
            }}
          />
        )}
      </Pdf>
    </td>
    <td className="patient-row-data text-center">
      <Button
        dataTestID={`view-patient-${patient.id}`}
        icon={<EyeIcon />}
        link={`/patients/${patient.id}`}
      />
    </td>
  </tr>
);

PatientRow.defaultProps = {
  selectedPatient: undefined,
};

PatientRow.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    education: PropTypes.number,
    sex: PropTypes.string,
    race: PropTypes.string,
    mrn: PropTypes.string,
    last_assessment_date: PropTypes.string,
  }).isRequired,
  generatePdf: PropTypes.func.isRequired,
  pdfVisible: PropTypes.bool.isRequired,
  setPdfVisible: PropTypes.func.isRequired,
  pdfRef: PropTypes.object.isRequired,
  selectedPatient: PropTypes.object,
};

export default PatientRow;
