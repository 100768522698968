import React, { useContext } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../shared/Form';
import FormTextField from '../shared/FormTextField';
import useAddOrganization from '../../hooks/useAddOrganization';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import ControlledFormSelectionField from '../shared/ControlledFormSelectionField';
import useCountryStateCity from '../../hooks/useCountryStateCity';
import FormCheckboxField from '../shared/FormCheckboxField';

const organization = yup.object().shape({
  name: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  country: yup.string().required(),
  emr_enabled: yup.boolean().required(),
});

const AddOrganization = () => {
  const history = useHistory();
  const setBannerMessage = useContext(InformationBannerContext);

  const defaultValues = { country: 'US' };

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    control,
  } = useForm({
    resolver: yupResolver(organization),
    defaultValues,
  });

  // API handler
  const mutation = useAddOrganization((response) => {
    setBannerMessage('Added organization successfully.');
    history.push(`/organizations/${response.data.id}`);
  });

  const { countries, states, cities } = useCountryStateCity(
    watch('country'),
    watch('state'),
    watch('city'),
    setValue,
    getValues
  );

  return (
    <>
      <Form
        title="New Organization"
        description="Enter details about your organization below."
        onSubmit={handleSubmit((data) => mutation.mutate(data))}
        submitText="Create Organization"
        submitIcon={<PlusIcon />}
        cancelLink="/organizations"
        isPosting={mutation.isLoading}
        isError={mutation.isError}
        error={mutation.error}
        validationErrors={errors}
        resourceType="organization"
      >
        <FormTextField
          register={register}
          error={errors.name}
          title="Organization Name"
          formKey="name"
        />
        <ControlledFormSelectionField
          error={errors.country}
          title="Country"
          formKey="country"
          options={countries}
          control={control}
          defaultValue={defaultValues.country}
          setValue={setValue}
        />
        <ControlledFormSelectionField
          error={errors.state}
          title="State"
          formKey="state"
          options={states}
          control={control}
          setValue={setValue}
        />
        <ControlledFormSelectionField
          error={errors.city}
          title="City"
          formKey="city"
          options={cities}
          control={control}
          setValue={setValue}
        />
        <FormCheckboxField
          register={register}
          error={errors.emr_enabled}
          title="EMR Enabled"
          formKey="emr_enabled"
        />
      </Form>
    </>
  );
};

export default AddOrganization;
