import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import '../../css/shared/FormTextField.css';
import { convertErrorToReadableText } from '../../utils/format';

const FormSelectionField = ({
  title,
  formKey,
  options,
  defaultValue,
  error,
  register,
}) => (
  <div className="form-textfield-grid">
    <label htmlFor={formKey} className="form-label">
      {title}
    </label>
    <div className="form-input">
      <select
        id={formKey}
        name={formKey}
        className="form-field"
        defaultValue={defaultValue}
        {...register(formKey, { required: 'This is required' })}
      >
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.title}
          </option>
        ))}
      </select>

      {error && (
        <div className="form-textfield-error">
          <ExclamationCircleIcon className="form-icon" />
        </div>
      )}

      <div className="form-error-container">
        {error && (
          <span className="form-error-text">
            {convertErrorToReadableText(error.message)}
          </span>
        )}
      </div>
    </div>
  </div>
);

FormSelectionField.defaultProps = {
  error: undefined,
  defaultValue: '',
};

FormSelectionField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  error: PropTypes.object,
  register: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.string,
};

export default FormSelectionField;
