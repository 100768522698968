import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PencilIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';
import DetailItem from '../shared/DetailItem';
import DetailView from '../shared/DetailView';
import useUser from '../../hooks/useUser';
import Button from '../shared/Button';
import { useAuth } from '../../hooks/useAuth';
import {
  displayableUserStatus,
  stripPhoneNumberFormatting,
  formatPhoneNumber,
  displayableRole,
} from '../../utils/format';
import DetailItemOrganizationList from '../shared/DetailItemOrganizationList';
import DetailDateItem from '../shared/DetailDateItem';
import {
  userCanActivateUser,
  userCanDeactivateUser,
  userCanEditUser,
} from '../../utils/privileges';
import LoadingDetail from '../shared/LoadingDetail';
import useDisableUser from '../../hooks/useDisableUser';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import useEnableUser from '../../hooks/useEnableUser';
import DisplayError from '../shared/DisplayError';
import ConfirmationDialog from '../shared/ConfirmationDialog';

const ShowUser = () => {
  const { organization, user: currentUser } = useAuth();
  const { userID } = useParams();
  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useUser(organization.id, userID);

  const [showActivateDialog, setShowActivateDialog] = useState(false);
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const setBannerMessage = useContext(InformationBannerContext);
  const enableUserMutation = useEnableUser(organization.id, userID, () => {
    setBannerMessage('Enabled user successfully.');
  });
  const disableUserMutation = useDisableUser(organization.id, userID, () => {
    setBannerMessage('Disabled user successfully.');
  });

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  const buttons = [];
  if (userCanEditUser(currentUser, user)) {
    buttons.push(
      <Button
        key="edit"
        name="Edit"
        icon={<PencilIcon />}
        link={`/users/${userID}/edit`}
        isSecondaryStyle
      />
    );
  }

  if (userCanDeactivateUser(currentUser, user) && user.active === true) {
    buttons.push(
      <Button
        key="deactivate"
        name="Disable"
        icon={<XCircleIcon />}
        isLoading={disableUserMutation.isLoading}
        onClick={() => {
          setShowDeactivateDialog(true);
        }}
        isSecondaryStyle
      />
    );
  }

  if (userCanActivateUser(currentUser, user) && user.active === false) {
    buttons.push(
      <Button
        key="activate"
        name="Enable"
        icon={<CheckCircleIcon />}
        isLoading={enableUserMutation.isLoading}
        onClick={() => {
          setShowActivateDialog(true);
        }}
        isSecondaryStyle
      />
    );
  }

  return (
    <>
      <ConfirmationDialog
        title="Disable User"
        message="Are you sure you would like to disable this user?"
        open={showDeactivateDialog}
        setOpen={setShowDeactivateDialog}
        destructiveButtonTitle="Disable"
        destructiveFunction={() => {
          disableUserMutation.mutate();
          setShowDeactivateDialog(false);
        }}
      />

      <ConfirmationDialog
        title="Enable User"
        message="Are you sure you would like to enable this user?"
        open={showActivateDialog}
        setOpen={setShowActivateDialog}
        destructiveButtonTitle="Enable"
        destructiveFunction={() => {
          enableUserMutation.mutate();
          setShowActivateDialog(false);
        }}
      />

      <DetailView
        title={`${user.first_name} ${user.last_name}`}
        description="User Details"
        additionalButtons={buttons}
        backLink="/users"
      >
        <>
          <DetailItem title="First Name" value={user.first_name} />
          <DetailItem title="Last Name" value={user.last_name} />
          <DetailItem
            title="Email"
            value={user.email}
            link={`mailto:${user.email}`}
          />
          <DetailItem title="Role" value={displayableRole(user.role)} />
          <DetailItem
            title="Confirmed"
            value={user.email_confirmed ? 'True' : 'False'}
          />
          <DetailItem title="Status" value={displayableUserStatus(user)} />
          <DetailItem
            title="Phone Number"
            value={formatPhoneNumber(user.phone)}
            link={`tel:${stripPhoneNumberFormatting(user.phone)}`}
          />
          <DetailDateItem title="Last Login" value={user.last_login_date} />
          <DetailItemOrganizationList
            title="Organizations"
            organizations={user.organizations.sort((a, b) => a.name > b.name)}
          />
        </>
      </DetailView>
    </>
  );
};

export default ShowUser;
