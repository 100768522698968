import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ id, children }) => (
  <ReactTooltip id={id} textColor="#ffffff" backgroundColor="#178FA1">
    {children}
  </ReactTooltip>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Tooltip;
