import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { moduleImageForKey, moduleTitleForKey } from '../../utils/modules';
import '../../css/modules/ModuleIcons.css';
import Tooltip from '../shared/Tooltip';
import { moduleSort } from '../../utils/sort';

const ModuleIcons = ({ modules }) => {
  if (modules.length === 0) {
    return (
      <div className="ml-3 font-medium">
        <p>None</p>
      </div>
    );
  }

  return (
    <div className="module-icons">
      {modules.sort(moduleSort).map((m) => (
        <Fragment key={m.id}>
          <div
            data-tip
            data-for={m.id}
            key={m.id}
            data-testid={m.id}
            className={
              m.canceled ? 'small-module-icons-canceled' : 'small-module-icons'
            }
          >
            {moduleImageForKey(m.key)}
          </div>
          <Tooltip id={m.id}>
            <>
              <span>{moduleTitleForKey(m.key)}</span>{' '}
              {m.canceled && <span>(Skipped)</span>}
            </>
          </Tooltip>
        </Fragment>
      ))}
    </div>
  );
};

ModuleIcons.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModuleIcons;
