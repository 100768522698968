import React, { useContext } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../shared/Form';
import FormTextField from '../shared/FormTextField';
import { useAuth } from '../../hooks/useAuth';
import useAddDevice from '../../hooks/useAddDevice';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';

const device = yup.object().shape({
  name: yup.string().required('Device name is a required field'),
  serial_number: yup.string().required(),
});

const AddDevice = () => {
  const { organization } = useAuth();
  const history = useHistory();
  const setBannerMessage = useContext(InformationBannerContext);

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(device),
  });

  // API handler
  const addDevice = useAddDevice(organization.id, async (response) => {
    setBannerMessage('Device added successfully.');
    history.push(`/devices/${response.data.id}`);
  });

  return (
    <>
      <Form
        title="New Device"
        description="Enter details about your device below."
        onSubmit={handleSubmit((data) => addDevice.mutate(data))}
        submitText="Create Device"
        submitIcon={<PlusIcon />}
        cancelLink="/devices"
        isPosting={addDevice.isLoading}
        isError={addDevice.isError}
        error={addDevice.error}
        validationErrors={errors}
      >
        <FormTextField
          register={register}
          error={errors.name}
          title="Device Name"
          formKey="name"
        />
        <FormTextField
          register={register}
          error={errors.serial_number}
          title="Serial Number"
          formKey="serial_number"
        />
      </Form>
    </>
  );
};

export default AddDevice;
