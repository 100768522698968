import { useQuery } from 'react-query';
import { Patients } from '../api/patients';
import { patientListSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function usePatients(organizationID, page) {
  return useQuery([organizationID, 'patients', 'list', page], async () => {
    const { data } = await Patients.list(organizationID, page);
    await validate(data, patientListSchema, 'patients', true);
    return data;
  });
}

export default usePatients;
