import React from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import '../../css/navigation/Menu.css';

const Menu = ({ visible, options, dismiss, offset, refp }) => (
  <Transition
    show={visible}
    enter="transition ease-out duration-100"
    enterFrom="opacity-0 translate-y-1"
    enterTo="opacity-100 translate-y-0"
    leave="transition ease-out duration-100"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 translate-y-1"
  >
    <div className="menu-container" ref={refp}>
      <div
        style={{ marginLeft: offset }}
        className="menu-inner-border"
        onMouseLeave={dismiss}
      >
        <div className="menu-item-container">
          <div className="menu-inner-container">
            {options.map((option) => (
              <MenuItem
                key={option.title}
                title={option.title}
                description={option.description}
                icon={option.icon}
                link={option.link}
                onClick={option.onClick}
                dismiss={dismiss}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </Transition>
);

Menu.defaultProps = {
  offset: 0,
};

Menu.propTypes = {
  visible: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  dismiss: PropTypes.func.isRequired,
  offset: PropTypes.number,
  refp: PropTypes.object.isRequired,
};

export default Menu;
