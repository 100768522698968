export const moduleImageForKey = (key) => (
  <img className="module-description-icon" alt={key} src={`/${key}.png`} />
);

export const moduleTitleForKey = (key) => {
  if (key === 'mh') {
    return 'MyHealth Survey';
  }
  if (key === 'npst') {
    return 'Processing Speed';
  }
  if (key === 'vmt') {
    return 'Visual Memory';
  }
  return 'N/A';
};

export const moduleDescriptionForKey = (key) => {
  if (key === 'npst') {
    return 'An assessment of processing speed measured by the correct number of symbols matched using a key within 2 minutes.';
  }
  if (key === 'vmt') {
    return 'An assessment of spatial and verbal episodic memory measured by the correct number of symbols placed in a grid matching a displayed pattern.';
  }
  return 'N/A';
};
