import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import '../../css/shared/Pagination.css';

const Pagination = ({ page, setPage, pageCount, isPreviousData }) => {
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('page', page + 1);
    history.push({ search: params.toString() });
  }, [page, history]);

  const buttonForPage = (i) => {
    if (i === undefined) {
      return (
        <span key="..." className="pagination-between">
          ...
        </span>
      );
    }
    return (
      <button
        type="button"
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={
          page === i
            ? 'pagination-page-number-current'
            : 'pagination-page-number'
        }
        onClick={() => {
          setPage(i);
        }}
      >
        {i + 1}
      </button>
    );
  };

  const pageElements = () => {
    if (pageCount <= 10) {
      // For fewer than 10 pages, render them all
      return [...Array(pageCount)].map((x, i) => buttonForPage(i));
    }
    // For more than 10 pages, render (when applicable):
    // - Previous page
    // - Current page
    // - Next page
    // - Last page
    // Where 'undefined' will show a '...' to indicate there are more pages in between buttons
    //

    // If we're almost on the last page, don't duplicate the last page
    if (page === pageCount - 2) {
      return [0, undefined, page - 1, page, page + 1].map((i) =>
        buttonForPage(i)
      );
    }

    // If we're on the last page, show only prior pages
    if (page + 1 >= pageCount) {
      return [0, undefined, page - 1, page].map((i) => buttonForPage(i));
    }

    // If we're not on the first page, show P-1, P, P+1, and N
    if (page > 0) {
      return [page - 1, page, page + 1, undefined, pageCount - 1].map((i) =>
        buttonForPage(i)
      );
    }

    // If we're on the first page, show P, P+1, and N
    return [page, page + 1, undefined, pageCount - 1].map((i) =>
      buttonForPage(i)
    );
  };

  return (
    <nav className="pagination-nav">
      <div className="pagination-wrapper">
        <button
          type="button"
          className={`${
            page <= 0 || isPreviousData ? 'invisible' : ''
          } pagination-btn`}
          disabled={isPreviousData || page <= 0}
          onClick={() => {
            setPage((p) => p - 1);
          }}
        >
          <ArrowNarrowLeftIcon className="pagination-arrow-left" />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">{pageElements()}</div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        <button
          type="button"
          className={`${
            page + 1 === pageCount || isPreviousData ? 'invisible' : ''
          } pagination-btn`}
          disabled={isPreviousData || page + 1 === pageCount}
          onClick={() => {
            if (page < pageCount) {
              setPage((p) => p + 1);
            }
          }}
        >
          Next
          <ArrowNarrowRightIcon
            className="pagination-arrow-right"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  isPreviousData: PropTypes.bool.isRequired,
};

export default Pagination;
