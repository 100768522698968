import api from '../config/api';
import { generateSortQueryString } from '../hooks/usePagination';

export const Assessments = {
  list: (organizationID, patientID) =>
    api.get(
      `/organizations/${organizationID}/patients/${patientID}/assessments?${generateSortQueryString(
        'created_at',
        'asc'
      )}`
    ),
};

export default Assessments;
