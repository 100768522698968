import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NoDataPageHeader from '../shared/NoDataPageHeader';
import PageHeader from '../shared/PageHeader';
import Search from '../shared/Search';
import PatientTable from './PatientTable';
import SkeletonTable from '../shared/SkeletonTable';
import usePatients from '../../hooks/usePatients';
import usePatientSearch from '../../hooks/usePatientSearch';
import SearchHeader from '../shared/SearchHeader';
import Pagination from '../shared/Pagination';
import { useAuth } from '../../hooks/useAuth';
import { usePagination } from '../../hooks/usePagination';
import DisplayError from '../shared/DisplayError';

const PatientList = () => {
  const { page, setPage } = usePagination();
  const { organization } = useAuth();

  // Which attribute the user selected from the search dropdown
  const [searchAttribute, setSearchAttribute] = useState('last-name');

  // State for what the user enters into the search field
  const [searchTerm, setSearchTerm] = useState('');

  // Query for loading patients
  const {
    data: response,
    isLoading,
    isError,
    isFetching,
    error,
    isPreviousData,
  } = usePatients(organization.id, page);

  // State for what the searchTerm was on the last search attempt
  // This is different from searchTerm because we don't want to
  // make queries when searchTerm changes, which is when the textfield changes.
  // We only want to make queries when they press Enter or tap Search.
  const [previousSearchTerm, setPreviousSearchTerm] = useState('');

  // State to handle whether the user has requested a search,
  // either through hitting Enter or tapping Search
  const [isSearching, setIsSearching] = useState(false);

  const {
    data: searchResultResponse,
    isLoading: isLoadingSearch,
    isError: isErrorSearch,
    error: searchError,
  } = usePatientSearch(
    organization.id,
    previousSearchTerm,
    searchAttribute,
    isSearching
  );

  const startSearch = () => {
    if (searchTerm !== '') {
      setIsSearching(true);
      setPreviousSearchTerm(searchTerm);
    }
  };

  const resetSearch = () => {
    setIsSearching(false);
    setSearchTerm('');
    setPreviousSearchTerm('');
  };

  useEffect(() => {
    // When the organization changes, we need to reset the page number
    // and any active search term
    resetSearch();
    setPage(0);
  }, [organization]);

  // Loading state
  if (isLoading) {
    return <PatientListLoading organization={organization} />;
  }

  // Error state
  if (isError) {
    return <DisplayError error={error} />;
  }

  // Empty state
  if (
    response === undefined ||
    response.data === undefined ||
    response.data.length === 0
  ) {
    return (
      <NoDataPageHeader
        title="No Patients Found"
        subtitle={organization.name}
        description="There are no patients in this organization.
          To add patients, use the Cognition Chronicle mobile app."
        refreshing={isFetching}
      />
    );
  }

  // Searching
  if (isSearching) {
    return (
      <>
        <PatientListHeader
          organization={organization}
          refreshing={isFetching}
        />
        <Search
          placeholder="Last name, date of birth, or MRN"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearch={startSearch}
          isLoadingSearch={isLoadingSearch}
          searchAttribute={searchAttribute}
          setSearchAttribute={setSearchAttribute}
          options={[
            {
              name: 'Last Name',
              value: 'last-name',
              placeholder: 'Enter last name to search',
            },
            {
              name: 'Date of Birth',
              value: 'date-of-birth',
              placeholder: 'Enter date of birth (YYYY-MM-DD)',
            },
            { name: 'MRN', value: 'mrn', placeholder: 'Enter MRN to search' },
          ]}
        />

        {searchResultResponse && (
          <SearchHeader
            searchResults={searchResultResponse.data}
            searchTerm={previousSearchTerm}
            isError={isErrorSearch}
            error={searchError}
            isLoadingSearch={isLoadingSearch}
            resetSearch={resetSearch}
          />
        )}

        {searchResultResponse && searchResultResponse.data && (
          <PatientTable patients={searchResultResponse.data} />
        )}
      </>
    );
  }

  // Populated, not searching
  return (
    <>
      <PatientListHeader organization={organization} refreshing={isFetching} />
      <Search
        placeholder="Last name, date of birth, or MRN"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearch={startSearch}
        isLoadingSearch={isLoadingSearch}
        searchAttribute={searchAttribute}
        setSearchAttribute={setSearchAttribute}
        options={[
          {
            name: 'Last Name',
            value: 'last-name',
            placeholder: 'Enter last name to search',
          },
          {
            name: 'Date of Birth',
            value: 'date-of-birth',
            placeholder: 'Enter date of birth (YYYY-MM-DD)',
          },
          { name: 'MRN', value: 'mrn', placeholder: 'Enter MRN to search' },
        ]}
      />
      <PatientTable patients={response.data} />
      <Pagination
        page={page}
        setPage={setPage}
        pageCount={response.paging.page_count}
        isPreviousData={isPreviousData}
      />
    </>
  );
};

const PatientListHeader = ({ organization, refreshing }) => (
  <PageHeader
    title="Patients"
    subtitle={organization.name}
    description="The patients for this organization are listed below."
    refreshing={refreshing}
  />
);

const PatientListLoading = ({ organization }) => (
  <>
    <PatientListHeader organization={organization} refreshing />
    <SkeletonTable />
  </>
);

PatientListHeader.propTypes = {
  organization: PropTypes.object.isRequired,
  refreshing: PropTypes.bool.isRequired,
};

PatientListLoading.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default PatientList;
