/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import '../../css/shared/ErrorAlert.css';
import { SupportEmail } from '../../utils/constants';

const ErrorAlert = ({ title, message }) => {
  // Log any errors
  console.log(message);
  return (
    <div className="mt-14">
      <div className="error-alert" role="alert">
        <p className="font-bold">{title}</p>
        <p>An error occurred while processing your request.</p>
        <p>
          If this problem persists, please contact{' '}
          <a className="underline font-bold" href={`mailto:${SupportEmail}`}>
            Support
          </a>
          .
        </p>
        <button
          className="underline font-bold mt-4"
          type="button"
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

ErrorAlert.defaultProps = {
  title: 'Request Failed',
};

ErrorAlert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default ErrorAlert;
