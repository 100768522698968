import React, { useContext } from 'react';
import { SaveIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../shared/Form';
import FormTextField from '../shared/FormTextField';
import FormSelectionField from '../shared/FormSelectionField';
import LoadingDetail from '../shared/LoadingDetail';
import useAddUser from '../../hooks/useAddUser';
import { useAuth } from '../../hooks/useAuth';
import FormMultiSelectionField from '../shared/FormMultiSelectionField';
import { useAllOrganizations } from '../../hooks/useOrganizations';
import generateRoles from '../../utils/generateRoles';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import PhoneNumberTextField from '../shared/PhoneNumberTextField';
import DisplayError from '../shared/DisplayError';

const userSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  role: yup.string().required(),
  phone: yup.string(),
  organization_ids: yup.array().min(1),
});

const AddUser = () => {
  const { organization, user } = useAuth();
  const history = useHistory();
  const setBannerMessage = useContext(InformationBannerContext);

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  // API handler
  const mutation = useAddUser(organization.id, (response) => {
    setBannerMessage('Added user successfully.');
    history.push(`/users/${response.data.id}`);
  });

  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useAllOrganizations(user);

  // Organizations come from the GetOrganization API for Super Admins
  // so they can add to organizations they're not currently part of.
  //
  // For Admins, Organizations come from their User object.
  const getOrganizations = () => {
    if (user.role === 'super_admin') {
      return response.data;
    }
    return user.organizations;
  };

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  return (
    <>
      <Form
        title="Add User"
        description="Add details about the user below."
        onSubmit={handleSubmit((data) => mutation.mutate(data))}
        submitText="Add User"
        submitIcon={<SaveIcon />}
        cancelLink="/users"
        isPosting={mutation.isLoading}
        isError={mutation.isError}
        error={mutation.error}
        validationErrors={errors}
        resourceType="user"
      >
        <FormTextField
          register={register}
          error={errors.first_name}
          title="First Name"
          formKey="first_name"
        />
        <FormTextField
          register={register}
          error={errors.last_name}
          title="Last Name"
          formKey="last_name"
        />
        <FormTextField
          register={register}
          error={errors.email}
          title="Email"
          formKey="email"
        />
        <FormSelectionField
          title="Role"
          formKey="role"
          error={errors.role}
          register={register}
          defaultValue="user"
          options={generateRoles(user.role)}
        />

        <PhoneNumberTextField
          error={errors.phone}
          title="Phone Number"
          formKey="phone"
          control={control}
          setValue={setValue}
        />

        <FormMultiSelectionField
          title="Organizations"
          formKey="organization_ids"
          error={errors.organization_ids}
          register={register}
          defaultValues={[organization.id]}
          errorDescription="Please select at least one organization."
          disabledExplanation="Current Organization"
          disabledOption={organization.id}
          setValue={setValue}
          warningDialogTitle="Current Organization"
          warningDialogDescription={`Your current organization (${organization.name}) cannot be deselected. To deselect this option, please switch organizations.`}
          options={getOrganizations().map((o) => ({
            value: o.id,
            title: o.name,
          }))}
        />
      </Form>
    </>
  );
};

export default AddUser;
