/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { SearchIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import Button from './Button';
import '../../css/shared/Search.css';
import '../../css/shared/DatePicker.css';
import {
  displayableDateFromDate,
  getMonth,
  getYear,
  months,
  years,
} from '../../utils/format';

const currentlySelectedOption = (options, searchAttribute) =>
  options.filter((o) => o.value === searchAttribute)[0];

const Search = ({
  searchTerm,
  setSearchTerm,
  onSearch,
  isLoadingSearch,
  searchAttribute,
  setSearchAttribute,
  options,
}) => {
  const [dobDate, setDobDate] = useState();

  useEffect(() => {
    if (searchTerm === undefined || searchTerm === '') {
      setDobDate(undefined);
    }
  }, [searchTerm]);

  return (
    <div className="search">
      <div>
        <select
          id="searchTerm"
          name="searchTerm"
          className="search-field-selection"
          defaultValue={searchAttribute}
          onBlur={(e) => {
            setSearchTerm('');
            setDobDate(undefined);
            setSearchAttribute(e.target.value);
          }}
          onChange={(e) => {
            setSearchTerm('');
            setDobDate(undefined);
            setSearchAttribute(e.target.value);
          }}
        >
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.name}
            </option>
          ))}
        </select>
      </div>
      <div className="search-box">
        <div className="search-wrapper">
          <div className="search-inner-wrapper">
            <div className="search-input-wrapper">
              {currentlySelectedOption(options, searchAttribute).value !==
                'date-of-birth' && (
                <input
                  id="search"
                  className="search-input"
                  placeholder={`${
                    currentlySelectedOption(options, searchAttribute)
                      .placeholder
                  }`}
                  type="search"
                  name="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearch();
                    }
                  }}
                />
              )}

              {currentlySelectedOption(options, searchAttribute).value ===
                'date-of-birth' && (
                <DatePicker
                  placeholderText="Enter date of birth to search"
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="date-picker">
                      <button
                        className="date-picker-button"
                        type="button"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {'<'}
                      </button>
                      <select
                        className="date-picker-selection"
                        value={months[getMonth(date)]}
                        onChange={({ target: { value: changedValue } }) =>
                          changeMonth(months.indexOf(changedValue))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        className="date-picker-selection"
                        value={getYear(date)}
                        onChange={({ target: { value: changedValue } }) => {
                          changeYear(changedValue);
                        }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        type="button"
                        className="date-picker-button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  className="search-input"
                  selected={dobDate}
                  onChange={(value) => {
                    setSearchTerm(displayableDateFromDate(value));
                    setDobDate(value);
                  }}
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Button
        name="Search"
        icon={<SearchIcon />}
        onClick={onSearch}
        isLoading={isLoadingSearch}
        additionalStyle="search-button"
      />
    </div>
  );
};

Search.defaultProps = {
  setSearchTerm: () => {},
};

Search.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  isLoadingSearch: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchAttribute: PropTypes.string.isRequired,
  setSearchAttribute: PropTypes.func.isRequired,
};

export default Search;
