import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { useQueryClient } from 'react-query';
import Menu from './Menu';
import AppLogo from '../shared/AppLogo';
import NavigationMenuItem from './NavigationMenuItem';
import SuperAdminMenu from './SuperAdminMenu';
import '../../css/navigation/Navigation.css';
import { useAuth } from '../../hooks/useAuth';
import useSwitchCurrentOrganization from '../../hooks/useSwitchCurrentOrganization';
import generateMenuOptions from '../../utils/generateMenuOptions';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import UserMenu from './UserMenu';

const Navigation = () => {
  const queryClient = useQueryClient();
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const [organizationMenuVisible, setOrganizationMenuVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const userMenuRef = useRef();
  const orgMenuRef = useRef();
  const menuRef = useRef();

  const userMenuButtonRef = useRef();
  const orgMenuButtonRef = useRef();
  const menuButtonRef = useRef();

  useOnClickOutside(userMenuRef, userMenuButtonRef, () =>
    setUserMenuVisible(false)
  );
  useOnClickOutside(orgMenuRef, orgMenuButtonRef, () =>
    setOrganizationMenuVisible(false)
  );
  useOnClickOutside(menuRef, menuButtonRef, () => setMenuVisible(false));

  const { user, organization, updateUser } = useAuth();
  const history = useHistory();
  const switchCurrentOrganization = useSwitchCurrentOrganization(
    user.id,
    (response) => {
      queryClient.invalidateQueries();
      updateUser(response.data);
      history.push(`/`);
    }
  );

  const generateOrganizationMenu = (currentOrg) =>
    user.organizations
      .filter((org) => org.id !== currentOrg.id)
      .sort((o1, o2) =>
        o1.name.toLowerCase().localeCompare(o2.name.toLowerCase())
      )
      .map((org) => ({
        title: org.name,
        description: 'Switch to this organization',
        icon: <OfficeBuildingIcon />,
        onClick: () => {
          switchCurrentOrganization.mutate(org.id);
        },
      }));

  function showMenu() {
    // If another menu is open, show this one with a delay to avoid a flicker
    if (userMenuVisible || organizationMenuVisible) {
      setUserMenuVisible(false);
      setOrganizationMenuVisible(false);
      setTimeout(() => {
        setMenuVisible(true);
      }, 150);
    } else {
      setMenuVisible(true);
    }
  }

  function showOrganizationMenu() {
    // If another menu is open, show this one with a delay to avoid a flicker
    if (userMenuVisible || menuVisible) {
      setUserMenuVisible(false);
      setMenuVisible(false);
      setTimeout(() => {
        setOrganizationMenuVisible(true);
      }, 150);
    } else {
      setOrganizationMenuVisible(true);
    }
  }

  function showUserMenu() {
    // If another menu is open, show this one with a delay to avoid a flicker
    if (organizationMenuVisible || menuVisible) {
      setOrganizationMenuVisible(false);
      setMenuVisible(false);
      setTimeout(() => {
        setUserMenuVisible(true);
      }, 150);
    } else {
      setUserMenuVisible(true);
    }
  }

  function dismissAllMenus() {
    setUserMenuVisible(false);
    setOrganizationMenuVisible(false);
    setMenuVisible(false);
  }

  return (
    <div>
      <div className="navigation">
        <div className="navigation-inner">
          <div className="navigation-items">
            <div className="navigation-item-logo">
              <AppLogo />
            </div>

            {/* Right-hand menu options */}
            <div className="navigation-items-right">
              <NavigationMenuItem
                name={organization.name}
                menuVisible={organizationMenuVisible}
                setMenuVisible={showOrganizationMenu}
                enabled={user.organizations.length > 1}
                buttonRef={orgMenuButtonRef}
              />
              <NavigationMenuItem
                name={`${user.first_name} ${user.last_name}`}
                menuVisible={userMenuVisible}
                setMenuVisible={showUserMenu}
                buttonRef={userMenuButtonRef}
              />
              <NavigationMenuItem
                name="Menu"
                menuVisible={menuVisible}
                setMenuVisible={showMenu}
                buttonRef={menuButtonRef}
              />
            </div>
          </div>
        </div>
      </div>

      <Menu
        visible={organizationMenuVisible}
        options={generateOrganizationMenu(organization)}
        dismiss={dismissAllMenus}
        offset={365}
        refp={orgMenuRef}
      />
      <UserMenu
        visible={userMenuVisible}
        dismiss={dismissAllMenus}
        offset={200}
        refp={userMenuRef}
      />

      {user.role === 'super_admin' || (
        <Menu
          visible={menuVisible}
          options={generateMenuOptions(user.role)}
          dismiss={dismissAllMenus}
          offset={600}
          refp={menuRef}
        />
      )}

      {user.role === 'super_admin' && (
        <SuperAdminMenu
          visible={menuVisible}
          options={generateMenuOptions(user.role)}
          dismiss={dismissAllMenus}
          refp={menuRef}
        />
      )}
    </div>
  );
};

export default Navigation;
