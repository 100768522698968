import { useQuery } from 'react-query';
import { Patients } from '../api/patients';
import { patientSchema } from '../schemas/schemas';
import validate from '../utils/validate';

function usePatient(organizationID, patientID) {
  return useQuery([organizationID, patientID, 'patients', 'show'], async () => {
    const { data } = await Patients.get(organizationID, patientID);
    await validate(data, patientSchema, 'patient', false);
    return data;
  });
}

export default usePatient;
